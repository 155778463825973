import React, { useState, useEffect, useCallback } from "react";
import { Table, Button } from "antd";
import "antd/dist/antd.min.css";
import useAxiosPrivate from "./hooks/useAxiosPrivate.js";

import { useMediaQuery } from 'react-responsive';
import { sportLeagueMap, sportMap } from "./CONSTANTS.js";

const MyLeaguePorts = (props) => {


  const [isLoading, setIsLoading] = useState(false);
  const [tableData,setTableData] = useState([{}]);
  

  const axiosPrivate = useAxiosPrivate();

  const isXSmall = useMediaQuery({maxWidth:575});

  const [anyDeleteable,setAnyDeleteable] = useState(false);


  const fetchData = useCallback(() => {
    setIsLoading(true);
    var data = [];
    const dataSend = {league_id:props.league_id};
    //console.log(dataSend);
    axiosPrivate.post('/league/getMyPortfolios',dataSend)
    .then((res) => {
      console.log("get user league portfolio data response");
      //console.log(res.data); //debugger
      for (let i=0; i<res.data.length; ++i){
        var obj;
        var record = res.data[i];
        //console.log(record);
        obj = {
          user: record.username,
          pname:record.port_name,
          port_id: record.port_id,
          worth: (Number(record.cash)+Number(record.val)).toFixed(2),
          lg_started: record.lg_started,
          port_started: record.p_started,
          port_type: record.lg_type,
          port_format: record.lg_format,
          timeframe: record.timeframe,
          subtimeframe: record.subtimeframe,
          port_year: record.time_year,
          port_sport_league: sportLeagueMap[record.sport_league_id],
          port_sport_league_id: record.sport_league_id,
          port_sport: sportMap[record.sport_id]

        }
        if (!(record.lg_started && record.p_started)) {
          //console.log("setting deletable")
          //console.log(record.lg_started)
          //console.log(record.p_started)
          setAnyDeleteable(true);
        }
        data.push(obj)
      }
      setTableData(data);
      setIsLoading(false);
    })
  }, [axiosPrivate,props.league_id])

  useEffect( () => {
    fetchData();
  }, [fetchData]); //empty dependency array means function only called once on mount


  const removePortFromLeague = async(e,record) => {
    console.log("removing portfolio from league");
    //console.log(e);
    console.log(record);
    const data = {
      league_id:props.league_id,
      port_id: record.port_id
    }

    try {
      await axiosPrivate.put('/portfolio/removeFromLeague',data);

      fetchData();
    }
    catch (err) {

    }
  }

  const goToPortfolio = (e,record) => {
    props.portCallback(record);
  }


  var columns = [
    {
      title: "Portfolio Name",
      dataIndex: "pname",
      key: "pname"
    },
    {
      title: "Total Worth",
      dataIndex: "worth",
      key: "worth",
      render : (text) => (
        "$"+ text
      ),
      sorter: {
        compare: (a, b) => a.worth - b.worth
      }
    },
    {
      title: "Go To Portfolio",
      dataIndex: "key",
      key: "key",
      render : (text,record) => (
        <Button onClick={(e) => {goToPortfolio(e,record)}} >Go To Portfolio</Button>
      )
    },

  ];

  if (anyDeleteable){
    columns.push(    {
      title: "Remove From League",
      responsive: ['sm'],
      render : (text,record) => (
        !(record.lg_started && record.port_started) ? <Button onClick={(e) => {removePortFromLeague(e,record)}} size={isXSmall ? "small" : "large"} >Remove From League</Button> : <span></span>

      )
    })
  }

  const expandableRows = {
    expandedRowRender: (record) => (
      <span>
        <div>{!(record.lg_started && record.port_started) ? <Button onClick={(e) => {removePortFromLeague(e,record)}} >Remove From League</Button> : <span></span>}</div>
      </span>
    ),
    rowExpandable: (record) => isXSmall && !(record.lg_started && record.port_started)
  }


    return (
      <div>
        <p>My Portfolios in this League</p>
        <Table
          dataSource={tableData}
          columns={columns}
          pagination={false}
          loading={isLoading}
          expandable={anyDeleteable ? expandableRows: null}
        />
      </div>
    );

  }

  export default MyLeaguePorts