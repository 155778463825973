import axios from "axios";

//const BASE_URL = 'http://localhost:8080';
//const BASE_URL = 'https://keen-answer-396101.uk.r.appspot.com';
const BASE_URL = 'https://server.spoxmarket.com';

export default axios.create({
  baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {'Content-Type': 'application/json'},
  withCredentials: true
});