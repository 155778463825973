import React, { useState } from "react";
import ReactEcharts from "echarts-for-react";
import { useCallback, useEffect } from "react";
import axios from './axios';
import { Button } from "antd";

const StockPerformance = (props) => {


  const [chartOptions,setChartOptions] = useState({})

  const getValues = useCallback(async () => {

    const dataSend = {equity_id:props.eqID};
    console.log(dataSend);
    const response = await axios.post('/getMarket/stockHistory',dataSend);
    var priceData  = [];
    var divData = [];
    var xaxisData = [];
    console.log("stock history response");
    //console.log(response);
    console.log(response.data);
    response.data.forEach( (record) => {
      if (record.subtimeframerecord===null || record.subtimeframerecord===0){
        return;
      }
      xaxisData.push("Week " + record.subtimeframerecord);
      priceData.push(Number(record.price).toFixed(2));
      divData.push(Number(record.dividend).toFixed(2));
    })
    console.log(priceData.length)
    if (!props?.hist){
      xaxisData.push("Current")
      priceData.push(response.data[0].currprice)
      divData.push(response.data[0].currdividend)
    }

    console.log(priceData);
    console.log(xaxisData);

    var isStockGreen = true;
    if (response.data.length>0){
      isStockGreen = response.data[0].currprice >= response.data[0].price;
    }

    
  const options = {
    animationDuration: 2000,
    title: {
      text: props.mobile? 'Season Performance' : 'Season Performance - ' + props.name
    },
    tooltip: {
      trigger: 'axis'
    },

    legend: {
        data: ['Price', 'Dividend'],
        bottom: 0
    },
    xAxis: {
      type: 'category', //try value if this isnt good
      data: xaxisData,
      name: props.mobile? "" : "Week"
    },
    yAxis: {
      type: 'value',
      /*min: function (value) {
        return value.min*0.95;
      },
      max: function (value) {
        return (value.max*1.05).toFixed(0);
      } */
      scale: true

    },
    series: [
      {
        name: "Price",
        data: priceData,
        type: 'line',
        label: {
            show: !props.mobile,
            formatter: '$ {c}'
        },
        color: isStockGreen ? 'green' : 'red'
      },
      {
        name: 'Dividend',
        data: divData,
        type: 'line',
        label: {
          show: !props.mobile,
          formatter: '$ {c}'
        },
        color: 'gold'
      },

    ]
  };

  setChartOptions(options);

  },[props.eqID,props.name,props.mobile,props.hist])

  useEffect( () => {
    console.log("loading portfolio performance page")
    //console.log(props.portInfo.port_id)
      getValues();
    }, [getValues]); //empty dependency array means function only called once on mount







  return (
    <span>
        {props.backFunction && <Button onClick={props.backFunction}>Back</Button>}
        <div >
            <ReactEcharts option={chartOptions}  />
        </div>
    </span>
  )
}



export default StockPerformance;
