import useAxiosPrivate from "./hooks/useAxiosPrivate.js";
import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Input } from "antd";
import './Global.css';
import { sportLeagueMap } from "./CONSTANTS.js";

const PortfolioSettings = (props) => {

    const axiosPrivate = useAxiosPrivate();

    const [isLoading, setIsLoading] = useState(false);
    const [portName,setPortName] = useState("");
    const [portViewable,setPortViewable] = useState("Yes");
    const [leagueTimeframe, setLeagueTimeframe] = useState("2023");
    const [leagueBudget,setLeagueBudget] = useState(0);
    const [leagueSport,setLeagueSport] = useState("");
    const [leagueShorting,setLeagueShorting] = useState("");
    const [leagueTransType,setLeagueTransType] = useState(0);
    const [leagueTransTypeString,setLeagueTransTypeString] = useState("");
    const [leagueTransAmnt,setLeagueTransAmnt] = useState(0.0);
    const [leagueInseason,setLeagueInseason] = useState("Yes");

    const [changeName,setChangeName] = useState(false);
    const [changeNameSuccess,setChangeNameSuccess] = useState(false);
    const [nameErrMsg,setNameErrMsg] = useState("");
    const [nameErr,setNameErr] = useState(false);
    const [changeViewable,setChangeViewable] = useState(false);
;
    const fetchData = useCallback( async() => {
        setIsLoading(true);
        const dataSend = {port_id:props.port_id};
        console.log(dataSend);
        try {
            const response = await axiosPrivate.post('/portfolio/getSettings',dataSend)

            const settings = response.data[0];

            console.log("get port settings data response");
            console.log(response.data); //debugger
 
            //setIsLoading(false);
            setPortName(settings.port_name);

            setLeagueBudget(settings.budget)
            setLeagueTransType(settings.trans_cost_type);
            if (settings.trans_cost_type===1){
                setLeagueTransTypeString("Global: $10 + 1%")
            }
            else{
                setLeagueTransAmnt(settings.transaction_cost);
                if (settings.trans_cost_type===0){
                    setLeagueTransTypeString("Free");
                }
                else if (settings.trans_cost_type===2){
                    setLeagueTransTypeString("Trade %");
                }
                else if (settings.trans_cost_type===3){
                    setLeagueTransTypeString("Flat Fee Per Trade");
                }
                else if (settings.trans_cost_type===4){
                    setLeagueTransTypeString("Fee Per Share");
                }
                
            }

            if (settings.timeframe<0){
                setLeagueTimeframe(String(settings.timeframe*-1));
            }
            else {
                setLeagueTimeframe(settings.timeframe_name);
            }
            setLeagueSport(sportLeagueMap[settings.sport_league_id]);
            if (settings.shorting){
                setLeagueShorting("Allowed");
            }
            else {
                setLeagueShorting("Blocked")
            }
            if (settings.inseason){
                setLeagueInseason("Yes");
            }
            else {
                setLeagueInseason("No");
            }
            if (settings.viewable){
                setPortViewable("Yes");
            }
            else {
                setPortViewable("No");
            }

            setIsLoading(false);
        }
        catch (err){

        }
      }, [axiosPrivate,props.port_id])
    
      useEffect( () => {
        fetchData();
      }, [fetchData]);

      const changeNameOn = () => {
        setChangeName(true);
        setChangeNameSuccess(false);
      }

      const updatePortName = async(e) => {
        console.log(e);
        const data = {
            new_portname: e.newname,
            port_id: props.port_id
        }

        console.log(data);

        try {
            await axiosPrivate.post('/portfolio/updateName',data);
            setChangeNameSuccess(true);
            await fetchData();
        }
        catch (err) {
            if (err.response?.data?.message){
                console.log(err.response?.data?.message);
                setNameErrMsg("Portfolio Name Update Failed: " + err.response?.data?.message);
              }
              else {
                setNameErrMsg("Portfolio Name Update Failed: " + err.message);
              }
              setNameErr(true);
        }
      }

      const changePrivacy = () => {
        setChangeViewable(true);
      }

      const updatePortViewable = async(e) => {
        console.log(e);
        var currViewable;
        if (portViewable==='Yes'){
            currViewable = true;
        }
        else {
            currViewable = false;
        }
        const data = {
            viewable: !currViewable,
            port_id: props.port_id
        }

        console.log(data);

        try {
            await axiosPrivate.put('/portfolio/updatePrivacy',data);
            setChangeViewable(false);
            await fetchData();
        }
        catch (err) {
            if (err.response?.data?.message){
                console.log(err.response?.data?.message);
              }
              else {
                console.log(err.message);
              }
              setChangeViewable(false);
              await fetchData();
        }
      }


      return (
        <span>
        {!isLoading &&
        <div>
            <br></br>Portfolio Name: {portName}
            <div class='divider'/> <Button size="small" onClick={changeNameOn}>Change Name</Button>
            <br></br> Sport: {leagueSport}
            <br></br> Timeframe: {leagueTimeframe}
            <br></br><br></br>

                <div> <b>Portfolio Format Settings:</b>
                    <br></br>Starting Budget: ${leagueBudget}
                    <br></br>Transaction Cost Type: {leagueTransTypeString}
                    {leagueTransType>1 && 
                    <span><br></br>Transaction Cost: {leagueTransAmnt}</span>}
                    <br></br>Shorting: {leagueShorting}
                    <br></br> In-Season Trading: {leagueInseason}
                    <br></br> Portfolio Viewable: {portViewable} <div class='divider'/> <Button size="small" onClick={changePrivacy}>Change</Button>
                </div>


            {changeName &&
            <span>
                {!changeNameSuccess && <span>
                    {nameErr && <div>{nameErrMsg}</div>}
                    {!nameErr && <span>
                        <div></div>
                        <div>
                            <Form onFinish={updatePortName}>
                                <Form.Item name="newname" label="Enter New Portfolio Name">
                                    <Input />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" >Update Name</Button>
                                </Form.Item> 
                            </Form>
                        </div>
                    </span>}
                </span>}
                {changeNameSuccess && <div> Name Successfully Updated!</div>}
            </span>}

            {changeViewable && <span>
                <Button onClick={updatePortViewable}>Confirm Change to Viewable</Button>
                    </span> }


        </div>}
        {isLoading && <div> Retrieving Portfolio Settings</div>}
        </span>

      )

}


export default PortfolioSettings;