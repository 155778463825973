import { useContext, useEffect } from "react";
import AuthContext from "../contexts/Authentication";
import {axiosPrivate} from "../axios"
import useRefreshToken from "./useRefreshToken";


const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const {auth} = useContext(AuthContext);

    //console.log ("use axios private");
    //console.log (auth);
    //console.log (auth.access);

    useEffect(() => {
        //console.log("use effect useaxiosprivate");
        //console.log(auth?.access);
        const reqIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.access}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        )
 
        const respIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevReq = error?.config;
                if (error?.response?.status === 403 && !prevReq?.sent) {
                    console.log("trying again but first get refresh");
                    prevReq.sent = true;
                    const response = await refresh();
                    console.log("Response of the refresh token attempt")
                    console.log(response);
                    const newAccessTok = response.access;
                    prevReq.headers['Authorization'] = `Bearer ${newAccessTok}`;
                    return axiosPrivate(prevReq);
                }
                return Promise.reject(error);
            }
        );
        return () => {
            //console.log("here 3 in useaxious private");
            axiosPrivate.interceptors.request.eject(reqIntercept);
            axiosPrivate.interceptors.response.eject(respIntercept);
        }
    },[auth,refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;