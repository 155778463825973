import {Form, Table, Button} from "antd";
import React, { useContext, useEffect,useState, useCallback} from "react";
import useGetMyLeagues from "./hooks/useGetMyLeagues.js";
import FrameContext from "./contexts/FrameContext.js";
import { timeframeToString } from "./CONSTANTS.js";
//import axios from 'axios';


const MyLeaguePage = (props) => {

  const {setFrame} = useContext(FrameContext);
  const getMyLeagues = useGetMyLeagues();
  const [tableData,setTableData] = useState([{}]);

  const [isErr,setIsErr] = useState(false);
  const [errMsg,setErrMsg] = useState("");
  const [isSubmitting,setIsSubmitting] = useState(false);


  const getMyLeagueInfo = useCallback(async() => {
    try {
      setIsSubmitting(true);
      const data = await getMyLeagues();
      console.log(data);
      setTableData(data.sort( (a,b) => (b.timeframe - a.timeframe)));
      setIsSubmitting(false);
    }
    catch (err) {
      console.log(err);
      setErrMsg(err.message);
      setIsErr(true);
      setIsSubmitting(false);
      return err;
    }
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect( () => {
    getMyLeagueInfo();
  }, [getMyLeagueInfo]); //empty dependency array means function only called once on mount



  const gotoLeague = (e,record) => {
    console.log("setting current league");
    console.log(e);
    console.log(record);
    props.leagueCallback(record);
  }

  const columns = [
    {
      title: "League Name",
      dataIndex: "league_name",
      key: "league_name"
    },
    {
      title: "Go to League",
      dataIndex: "key",
      key: "key",
      render : (text,record) => (
        <Button onClick={(e) => {gotoLeague(e,record)}} >Go To League</Button>
      )
    },
    {
      title: "Season",
      dataIndex: "timeframe",
      render: (text,record) => (
        timeframeToString[record.timeframe]
      ),
    }
  ]


  const createLeague = (e) => {
    setFrame("CreateLeague");
  }

  /*const layout = {
    labelCol: {span:6},
    wrapperCol: {span:14}
  } */
 

  return (

    <div>
      <span>
      {!isErr &&
        <Table
          title = {() => "League Results"}
          dataSource={tableData}
          columns={columns}
          loading={isSubmitting}
          size="middle"
        />
      }
      {isErr && <div>{errMsg}<p>Failed to retrieve your tables.</p>
      <Form>
        <Form.Item>
          <Button  onClick={getMyLeagueInfo} >Try Again?</Button>
        </Form.Item>
      </Form></div>}
      {(tableData.length===0) &&
    <span>
      <div>You are not part of any leagues. Please create or join one.</div>
      <Button  onClick={createLeague} >Create League</Button>
    </span>}
      </span>
    </div>
  )
}

export default MyLeaguePage;