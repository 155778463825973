import {Form, Table, Button} from "antd";
import { useEffect,useState,useCallback } from "react";
import useAxiosPrivate from "./hooks/useAxiosPrivate.js";
//import axios from 'axios';
import { useMediaQuery } from 'react-responsive';

const TransactionHistory = (props) => {

  const axiosPrivate = useAxiosPrivate();
  const [tableData,setTableData] = useState([{}]);

  const [isErr,setIsErr] = useState(false);
  const [isSubmitting,setIsSubmitting] = useState(false);


  const isXSmall = useMediaQuery({maxWidth:575});


  const getTransactionInfo = useCallback( async() => {
    try {
      setIsSubmitting(true);
      const dataSend = {
        port_id: props.port_id
      }
      const response = await axiosPrivate.post('/portfolio/getTransactionHistory',dataSend);
      console.log(response);
      const data = response.data.map ((record) => (
        {  
          name: record.fname ? record.fname + " " + record.lname : record.city + " " + record.nickname,
          quantity: record.quantity_change,
          price: record.price,
          cost: record.transaction_cost,
          totalCost: (Number(record.price)*-1*record.quantity_change - Number(record.transaction_cost)).toFixed(2),
          time: (new Date(record.time)).toLocaleString()
        }
      ))

      console.log(data);
      setIsErr(false);
      setTableData(data);
      setIsSubmitting(false);
    }
    catch (err) {
      console.log(err);
      setIsErr(true);
      setIsSubmitting(false);
      return err;
    }
  }, [axiosPrivate,props.port_id])


  useEffect( () => {
    getTransactionInfo();
  }, [getTransactionInfo]); //empty dependency array means function only called once on mount





  const columns = [
    {
      title: "Stock",
      dataIndex: "name",
      key: "name"
    },
    {
        title: isXSmall ? "#" : "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        render: (Text,record) => ((record.quantity>0 ? "Buy " : "Sell " ) + Math.abs(record.quantity))
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price"
      },
      {
        title: isXSmall ? "Fee" : "Transaction Cost",
        dataIndex: "cost",
        key: "cost"
      },
      {
        title: "Amount",
        dataIndex: "totalCost",
        key: "totcost"
      },
      {
        title: "Time",
        dataIndex: "time",
        key: "time"
      },
  ]

  /*const layout = {
    labelCol: {span:6},
    wrapperCol: {span:14}
  }*/


  return (

    <div>
      <span>
      {!isErr &&
      <span>
        {tableData.length>0 && 
          <Table
            title = {() => "Transaction History"}
            dataSource={tableData}
            columns={columns} 
            loading={isSubmitting}
            size={isXSmall ? "small" : "middle"}
          />
        }
        {tableData.length<=0 && <div>This portfolio has not made any transactions.</div>}
        </span>
      }
      {isErr && <div><p>Failed to retrieve your transactions.</p>
      <Form>
        <Form.Item>
          <Button  onClick={getTransactionInfo} >Try Again?</Button>
        </Form.Item>
      </Form></div>}
      </span>

    </div>
  )
}

export default TransactionHistory;