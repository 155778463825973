import { sportLeagueMap, sportMap } from "../CONSTANTS.js";
import useAxiosPrivate from "./useAxiosPrivate.js";


const useGetMyPortfolios = () => {

  const axiosPrivate = useAxiosPrivate();

  const getMyPortfolios = async() => {
    try {
      const response = await axiosPrivate.get('/portfolio/myPortfolios');
      console.log(response);
      const data = response.data.map ((record) => (
        {
          //changes to this need to also be made in MyLeaguePorts.js
          port_name: record.port_name,
          port_id: record.port_id,
          league_count: record.lg_count,
          worth: record.value,
          port_type: record.lg_type,
          timeframe: record.timeframe,
          subtimeframe: record.subtimeframe,
          port_format: record.lg_format,
          port_year: record.time_year,
          port_sport_league: sportLeagueMap[record.sport_league_id],
          port_sport_league_id: record.sport_league_id,
          port_sport: sportMap[record.sport_id]
        }
      ))
      return data;
    }
    catch (err) {
      console.log(err);
      return err;
    }
  }
  return getMyPortfolios;
}

export default useGetMyPortfolios;