import React, { useState, useEffect, useCallback } from "react";
import { Button, Table, } from "antd";
import "antd/dist/antd.min.css";
import useAxiosPrivate from "./hooks/useAxiosPrivate.js";
import { positionMap } from "./CONSTANTS.js";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';


import { useMediaQuery } from 'react-responsive';


const PortRoster = (props) => {

  //const isXSmall = useMediaQuery({maxWidth:575});

  const [isLoading, setIsLoading] = useState(false);
  const [tableData,setTableData] = useState([{}]);

  const subtimeframe = props.portInfo.subtimeframe;

  const [week,setWeek] = useState(props.mine ? subtimeframe : (subtimeframe-1));

  
  const axiosPrivate = useAxiosPrivate();

  const isLarge = useMediaQuery({minWidth:900});


  const getRosterHistory = useCallback(async (week) => {
    var data = [];
    const dataSend = {
      port_id:props.port_id,
      subtimeframe: week
    };

    try {
      console.log("Getting roster history")
      console.log(dataSend);
      const response = await axiosPrivate.post('/portfolio/getRosterHistory',dataSend)
      console.log(response);
      for (let i=0; i<response.data.length; ++i){
        var obj;
        var record = response.data[i];
        if (record?.player_id){
          obj = {
            name: record.fname + " " + record.lname,
            position: positionMap[record.position_id],
            team: record.city + " " + record.nickname,
            ent_id: record.player_id,
            restricted: record.locked,
            key: record.equity_id,
            multiplier: record.multiplier,
            dividends: record.dividends_earned,
            earnings: (record.multiplier*record.dividends_earned).toFixed(2),
            stats: getStatsString(record)

          };
        }
        else {
          obj = {
            name: record.city + " " + record.nickname,
            position: "TEAM",
            team: record.city + " " + record.nickname,
            ent_id: record.team_id,
            key: record.equity_id,
            restricted: record.locked,
            multiplier: record.multiplier,
            dividends: record.dividends_earned,
            earnings: (record.multiplier*record.dividends_earned).toFixed(2),
            stats: record.win===1 ? "W" : ""

          }
        }
        data.push(obj);
      }
      setTableData(data);
    }
    catch (err){

    }
  },[axiosPrivate,props.port_id])

  const fetchData = useCallback(async () => {

    setIsLoading(true);
    var data = [];
    const dataSend = {port_id:props.port_id};
    console.log(dataSend);
    try {
      var res;
      if (props.mine){
        res = await axiosPrivate.post('/portfolio/getRoster',dataSend)
        //console.log("getportfolio data response");
        //console.log(res.data); //debugger
        for (let i=0; i<res.data.length; ++i){
          var obj;
          var record = res.data[i];
          //console.log(record);


          if (record?.player_id){
            obj = {
              name: record.fname + " " + record.lname,
              position: positionMap[record.position_id],
              team: record.city + " " + record.nickname,
              ent_id: record.player_id,
              restricted: record.locked,
              key: record.equity_id,
              multiplier: record.multiplier

            };
          }
          else {
            obj = {
              name: record.city + " " + record.nickname,
              position: "TEAM",
              team: record.city + " " + record.nickname,
              ent_id: record.team_id,
              key: record.equity_id,
              restricted: record.locked,
              multiplier: record.multiplier

            }
          }
          if (obj.restricted){
            obj.name = obj.name + "*";
          }
          data.push(obj)
        }
        setTableData(data);
      }
      else {
        // get previous recorded stock hist
        getRosterHistory(subtimeframe-1);
      }
      setIsLoading(false);
    }
    catch (err) {

    }

  }, [axiosPrivate,props.port_id,props.mine,subtimeframe,getRosterHistory])

  useEffect( () => {
    fetchData();
  }, [fetchData]);



  const currColumns = [
    {
      title: "Position",
      dataIndex: "position",
      key: "position"
    },
    {
      title: "Multiplier",
      dataIndex: "multiplier",
      key: "multiplier"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },

    {
      title: "Team",
      dataIndex: "team",
      key: "team"
    }

  ];

  const histColumns = [
    {
      title: "Position",
      dataIndex: "position",
      key: "position"
    },
    {
      title: "Multiplier",
      dataIndex: "multiplier",
      key: "multiplier"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },

    {
      title: "Team",
      dataIndex: "team",
      key: "team"
    },
    {
      title: "Dividends",
      dataIndex: "earnings",
      key: "dividends"
    },
    {
      title: "Stats",
      dataIndex: "stats",
      key: "stats"
    }

  ];

  const getStatsString = (record) => {
    var string = '';
    var start = false;
    if (record?.PaYards!==0 && record?.PaYards!==null){
      string += record.PaYards + " Pa Yards"
      if (!start) {
        start = true;
      }
    }
    if (record?.PaTD!==0 && record?.PaTD!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.PaTD + " Pa TDs"
    }
    if (record.IntsThrown!==0 && record?.IntsThrown!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.IntsThrown + " INTs"
    }
    if (record.RuYards!==0 && record?.RuYards!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.RuYards + " Ru Yards"
    }
    if (record.RuTd!==0 && record?.RuTd!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.RuTd + " Ru TDs"
    }
    if (record.Fumbles!==0 && record?.Fumbles!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.Fumbles + " Fumb"
    }
    if (record.Receptions!==0 && record?.Receptions!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.Receptions + " Recs"
    }
    if (record.RecYards!==0 && record?.RecYards!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.RecYards + " Rec Yards"
    }
    if (record.RecTds!==0 && record?.RecTds!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.RecTds + " Rec TDs"
    }
    if (record.TwoPtConv!==0 && record?.TwoPtConv!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.TwoPtConv + " Two Pt"
    }
    if (record.OPIyards!==0 && record?.OPIyards!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.OPIyards + " OPI Yards"
    }
    if (record.DPIyards!==0 && record?.DPIyards!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.DPIyards + " DPI Yards"
    }
    if ((record.KRtds + record.PRtds)!==0  && record?.KRtds!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += (record.KRtds + record.PRtds) + " Return TDs"
    }
    if (record.FGAunder40!==0  && record?.FGAunder40!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.FGMunder40 + "/" + record.FGAunder40 + " under 40 FG"
    }
    if (record.FG40!==0  && record?.FG40!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.FG40 + " 40-49 FGM"
    }
    if (record.FG50!==0  && record?.FG50!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.FG50 + " 50-59 FGM"
    }
    if (record.FG60!==0  && record?.FG60!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.FG60 + " 60+ FGM"
    }
    if (record.XPA!==0  && record?.XPA!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.XPM + "/" + record.XPA + " Extra Points"
    }
    return string;
  }

  const changeWeek = (change) => {

    const newweek = week + change;
    if (newweek<0){
      return;
    }
    else if (newweek<19){
      return;
    }
    else if (newweek>22){
      return;
    }
    else if (!props.mine && newweek>=subtimeframe){ //other players cant see current roster
      return;
    }
    setIsLoading(true);
    setWeek(week+change);
    console.log(week)
    if (newweek===subtimeframe){
      fetchData();
    }
    else if (newweek<subtimeframe){
      getRosterHistory(newweek);
    }

    setIsLoading(false);

  }



    return (
      <div>
        <Button typ='primary' size='small' onClick={e=>{changeWeek(-1)}} icon={<LeftOutlined />} ></Button>
          <div>NFL Week: {week}</div>
          <Button typ='primary' size='small'onClick={e=>{changeWeek(1)}} icon={<RightOutlined />} ></Button>
        {<span>
        <Table
          dataSource={tableData}
          columns={week>=subtimeframe ? currColumns: histColumns}
          pagination={false}
          loading={isLoading}
          size= {isLarge ? "large" : "small"}
        />
        </span>}
      </div>
    );

  }

  export default PortRoster