import logo from './images/Spox_Final_Gold_On_Black.png';
//import goldLogoOnWhite from './logo_gold_clearback.svg';
import goldLogoOnWhite from './images/logo_gold_whiteback.png';
import textLogo from './images/Spox_Final_Gold_Text_2_PNG.png';
import 'antd/dist/antd.min.css'
import './App.css';
import AuthContext from './contexts/Authentication.js';
import useAxiosPrivate from "./hooks/useAxiosPrivate.js";
import useRefreshToken from './hooks/useRefreshToken';
import FrameContext from './contexts/FrameContext.js';
import { useMediaQuery } from 'react-responsive';
import { UserOutlined } from '@ant-design/icons';

import Body from "./Body";

import React, {useContext, useState, useEffect} from 'react';

import {
  Modal,
  Menu,
  Button,
  Form,
  Input,
  //Switch
} from "antd";
import axios from './axios';
import NotificationSettings from './NotificationSettings.js';

const { SubMenu } = Menu;

function App() {

  const isTabletOrMobile = useMediaQuery({maxWidth:1024});

  const {auth, setAuth} = useContext(AuthContext);
  const {frame,setFrame} = useContext(FrameContext);
  //const [apiresp,setapiresp] = useState("");
  const refresh = useRefreshToken();

  //const [frame, setFrame] = useState("Home");
  //setFrame("Home");
  const [SignUpvisible, setSignUpvisible] = useState(false);
  const [LogInvisible, setLogInvisible] = useState(false);
  const [loginMsg, setloginMsg] = useState("Log In")
  const [usrSignedIn, setusrSignedIn] = useState(false);
  const [myReferralCode,setMyReferallCode] = useState("");
  const [isAdmin, setisAdmin] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const [usrname, setusrname] = useState("");
  const [changePassvisible, setChangePassvisible] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(true);
  const [loggedOutModalVisible,setLoggedOutModalVisible] = useState(false);
  const [forgotPasswordVisible,setForgotPasswordVisible] = useState(false);
  const [resetPasswordVisible,setResetPasswordVisible] = useState(false);

  const [profileModalVisible,setProfileModalVisible] = useState(false);

  const [profileData,setProfileData] = useState({})
  const [emailVerificationStatus,setEmailVerificationStatus] = useState(false);

  const isDark = false;
  //const [isDark,setIsDark] = useState(false);

  useEffect(() => {
    const verifyRefreshToken = async () => {
      console.log("in verify refreshtoken");
        try{
            console.log("in verify refreshtoken");
            const response = await refresh();
            const user = response.user;
            const role = response.role;
            console.log(response);
            console.log(user)
            console.log(role);
            const access = response.access;
            //console.log("accessToken: " + access);
            setAuth({user, role, access});
            setusrSignedIn(true);
            setusrname(user);
            console.log("ref code is " + response.referralCode);
            setMyReferallCode(response.referralCode);
            setLogInvisible(false);
            //const adminStatus = getAdminStatus(role);
            //console.log("adminStatus: " + adminStatus);
            //setisAdmin(adminStatus);
        }
        catch (err){
            console.error(err);
            setusrSignedIn(false);
            setAuth({});
            setisAdmin(false);
            setusrname("");
            setProfileData({});
            setEmailVerificationStatus(false);
        }
        finally {
            setIsLoading(false);
        }
    }

    (!(auth?.access) ? verifyRefreshToken() : setIsLoading(false));

}, []) // eslint-disable-line react-hooks/exhaustive-deps

useEffect( () => {
    console.log("IsLoading value: " + isLoading);
},[isLoading])

/*
useEffect( () => {
  //console.log("IsDark value: " + isDark);
  if (isDark){
    document.body.classList.add('dark');
  }
  else {
    document.body.classList.remove('dark');
  }
}, [isDark]);

const systemPrefersDark = useMediaQuery(
  {
    query: "(prefers-color-scheme: dark)",
  },
  undefined,
  (isSystemDark) => setIsDark(isSystemDark)
);
*/

const getUserProfile = async () => {
  const response = await axiosPrivate.get('/user/getProfile')
  console.log(response);
  setProfileData(response.data[0]);
  setEmailVerificationStatus(response.data[0].email_verif);
}

//use effect for change of auth
useEffect( () => {

  console.log("auth changed");
  console.log(auth);
  console.log(myReferralCode)
  if (auth?.user){
    console.log("User logged in")
    setusrSignedIn(true);
    setusrname(auth.user);
    const adminStatus = getAdminStatus(auth.role);
    console.log("adminStatus: " + adminStatus);
    setisAdmin(adminStatus);
    getUserProfile();
  }
  else {
    if (usrSignedIn){
      console.log("User logged out")
      setLoggedOutModalVisible(true);
    }
    console.log("User not logged in");
    setusrSignedIn(false);
    setusrname("");
    setisAdmin(false);
    setProfileData({})
    setEmailVerificationStatus(false);
    setFrame("Home");

  }
},[auth]) // eslint-disable-line react-hooks/exhaustive-deps

  const getAdminStatus = (role) => {
    if (role > 40) return true;
    return false;
  }


  const showLogin = (msg) => {
    setErrMsg("");
    setloginMsg(msg);
    setLogInvisible(true);
  };
  
  const closeLogin = () => {
    setLogInvisible(false);
    setErrMsg("");
  };
  
  const showSignUp = () => {
    setErrMsg("");
    setSignUpvisible(true);
  };
  
  const closeSignUp = () => {
    setSignUpvisible(false);
    setErrMsg("");
  };

  const showProfile = () => {
    setProfileModalVisible(true);
  }

  const closeProfile = () => {
    setProfileModalVisible(false);
    setErrMsg("");
  }

  
  const showForgotPass = () => {
    setErrMsg("");
    setForgotPasswordVisible(true);
  }

  const closeForgotPass = () => {
    setErrMsg("");
    setForgotPasswordVisible(false);
  }

  const showResetPassword = () => {
    setErrMsg("");
    setResetPasswordVisible(true);
  }

  const closeResetPassword = () => {
    setResetPasswordVisible(false);
    setErrMsg("");
  }
  
  const handleClick = (e) => {
    //console.log(e);
    //console.log("handle click");
    //console.log("Clicked on button: " + e.key);
    setFrame(e.key);
  };

  const handleTitleClick = (e) => {
    //console.log(e);
    console.log("handle title click");
    //console.log("Clicked on button: " + e.key);
    if (!isTabletOrMobile){
      setFrame(e.key);
    }

  };

  const showChangePassword = () => {
    console.log("Open changePassowrd modal");
    setChangePassvisible(true);
  }

  const closeChangePass = () => {
    setChangePassvisible(false);
  }

  const submitSignUp = async (e) => {
    console.log("Handle sign up Submit");
    //console.log(e);
    //e.preventDefault();
    console.log("Username is: " + e.username);
    console.log("Referral code is: " + e.refCode);
    try {
      const response = await axios.post("/signup",
        //JSON.stringify({ user, pwd: passw }),
        JSON.stringify(e),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      //const token = response?.data?.token;
      //const access = response?.data?.roles;
      console.log(response);
      //setAuth({user, passw, access, token});
      //setusrSignedIn(true);
      // setusrname(user);
      closeSignUp();
      showLogin("Please login to complete signup");

    } catch (err) {
      console.log(err);

      if (!err?.response) {
        setErrMsg("No Server Response");
      }
      else {
        console.log(err.response);
        console.log(err.response.status);
        console.log(err.response.data.message);
        setErrMsg(err?.response?.data?.message);
/*           if (err.response?.status === 400){
          setErrMsg('Missing username or password');
        }
        else if (err.response?.status === 401){
          setErrMsg('Wrong password');
        }
        else if (err.response?.status === 401){
          setErrMsg('Wrong password');
        }
        else {
          setErrMsg("Other Error");
        } */
      }
    }


  };

  const submitLogIn = async (e) => {
    console.log("Handle log in Submit");
    //console.log(e);
    //e.preventDefault();
    console.log(e.username);
    var user = e.username;
    //var passw = e.pwd;
    try {
      const response = await axios.post("/login",
        JSON.stringify(e),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const access = response?.data?.accessToken;
      const role = response?.data?.role;
      console.log("accessToken: " + access);
      console.log("role: " + role);
      console.log("auth is: " + auth);
      console.log("auth is: " + auth.values);

      setAuth({user, role, access});
      setusrSignedIn(true);
      setusrname(user);
      setMyReferallCode(response.data?.referralCode);
      setLogInvisible(false);
      //const adminStatus = getAdminStatus(role);
      //console.log("adminStatus: " + adminStatus);
      //setisAdmin(adminStatus);
      //console.log(response.message);
      //console.log(response);
      //console.log(response.data);


    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      }
      else if (err.response?.status === 400){
        setErrMsg('Missing username or password');
      }
      else if (err.response?.status === 401){
        setErrMsg('Incorrect username and password combination.');
      }
      else {
        setErrMsg("Other Error");
      }
    }


  };

  const requestEmailVerification = async() => {
    try {

      await axiosPrivate.post('/user/requestEmailVerification');
      setErrMsg("");
    }
    catch (err) {
      setErrMsg("Error generating email information.")
    }
  }

  const submitEmailVerification = async(e) => {
    try {
      console.log(e);
      await axiosPrivate.post('/user/verifyEmail',e);
      setErrMsg("");
      setSuccessMsg("Email Successfully verified.")
      getUserProfile();
    }
    catch (err) {
      setErrMsg("Email Verification failed.")
    }
  }

  const submitForgotPassword = async(e) => {
    try {
      console.log(e);
      await axios.post('/forgotPassword',JSON.stringify(e),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        })

        closeForgotPass();
        showResetPassword();
    }
    catch (err) {
      setErrMsg("Error getting Security Code.")
    }
  }

  const submitResetPass = async(e) => {
    try {
      await axios.post('/forgotPassword/update',JSON.stringify(e),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
      closeResetPassword();
      showLogin("Password update successful. Please login with new password.");
    }
    catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      }
      else if (err.response?.status === 401){
        setErrMsg('Incorrect Security Code.');
      }
      else if (err.response?.status === 422){
        setErrMsg('Security Code Expired.');
      }
      else {
        setErrMsg("Other Error");
      }
    }
  }

  const logInInstead = () => {
    closeSignUp();
    closeForgotPass();
    showLogin("Log In");
  }

  const signUpInstead = () => {
    closeLogin();
    showSignUp();
  }

  const forgotPasswordInstead = () => {
    closeLogin();
    closeResetPassword();
    showForgotPass();
  }

  const resetPasswordInstead = () => {
    closeForgotPass();
    showResetPassword();
  }

  const submitLogout = async () => {
    console.log ("logging out");
    setAuth({});
    setusrSignedIn(false);
    setusrname("");
    setLogInvisible(false);
    setProfileModalVisible(false);
    setisAdmin(false);
    setFrame("Home");
    try {
      const response = await axios.get("/logout",
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log(response);
    }
    catch (err){

    }
  }

  const submitChangePass = async (e) => {
    console.log("Handle change password Submit");
    console.log(e);
    //e.preventDefault();
    console.log(e.username);
    console.log(e.pwd);
    var user = e.username;
    var passw = e.pwd;
    try {
      const response = await axiosPrivate.post("/updatePassword",
        JSON.stringify(e),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const access = response?.data?.accessToken;
      const role = response?.data?.role;
      console.log("accessToken: " + access);
      console.log("role: " + role);
      setAuth({user, passw, access});
      setusrSignedIn(true);
      setusrname(user);
      setChangePassvisible(false);
      const adminStatus = getAdminStatus(role);
      console.log("adminStatus: " + adminStatus);
      setisAdmin(adminStatus);
      console.log(response.message);
      console.log(response);
      console.log(response.data);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      }
      else if (err.response?.status === 400){
        setErrMsg('Missing username or password');
      }
      else if (err.response?.status === 401){
        setErrMsg('Wrong password');
      }
      else {
        setErrMsg("Other Error");
      }
    }


  };

  return (
/*     <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>

        <button onClick={hitBackend}> Click</button>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div> */
    <div className="App">
      <Modal
        title={loginMsg}
        open={LogInvisible}
        footer={null}
        onCancel={closeLogin}
      >
        <Form onFinish={submitLogIn}>
          <Form.Item label="Username" name="username" rules={[{ required: true, message: "Username required"  }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Password" name="pwd" rules={[{ required: true, message: "Password required"  }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item>
            {/*<Checkbox>Remember Me</Checkbox>*/}
            {/*<div>
              <a href="">Forgot Password?</a>
          </div>*/}
          </Form.Item>
          <div color='red'>{errMsg}</div>
          <Form.Item>
            <Button type="primary" htmlType="submit">Log In</Button>
            {<div>
              Don't Have an Account? Sign Up<Button type='link' onClick={signUpInstead}>here</Button>
            </div>}
            {<div>
              Forgot Your Password? Click<Button type='link' onClick={forgotPasswordInstead}>here</Button>
            </div>}
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Sign Up"
        open={SignUpvisible}
        footer={null}
        onCancel={closeSignUp}
      >
        <Form onFinish={submitSignUp}>
          <Form.Item label="Username" name="username" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="pwd"
            rules={[{ required: true, message: "Password required" }]}
              
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name = "confirm"
            label="Verify Password"
            rules={[ {required:true},
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('pwd') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password does not match!'));
                },
              }),
            ]}
            dependencies={["pwd"]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item label="First Name" name="fname" rules={[{ required: true, message:'First name required'  }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Last Name" name = "lname" rules={[{ required: true, message:'Last name required' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email" rules={[{ required: true, type: "email", message: "Not a valid E-mail." }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Referral Code" name="refCode">
            <Input />
          </Form.Item>
          <Form.Item label="Phone Number" name="phone" hidden={true}>
            <Input />
          </Form.Item>
          <div>{errMsg}</div>
          <Form.Item>
            <Button type="primary" htmlType="submit">Submit</Button>
            {<div>
              Already Have an Account? Log In<Button type='link' onClick={logInInstead}>here</Button>
            </div>}
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={"You have been logged out."}
        open={loggedOutModalVisible}
        footer={null}
        onCancel={(e)=>{setLoggedOutModalVisible(false)}}
      >
      </Modal>
      <Modal
        title="Forgot Password"
        open={forgotPasswordVisible}
        footer={null}
        onCancel={logInInstead}
      >
        <Form onFinish={submitForgotPassword}>
          <Form.Item label="Username or email" name="username" rules={[{ required: true, message: "Username/email required"  }]}>
            <Input />
          </Form.Item>
          <div>If an account with this username or email exists, you will receive an email with a code to update your password.</div>
          <div color='red'>{errMsg}</div>
          <Form.Item>
            <Button type="primary" htmlType="submit">Request Security Code</Button>
            {<div>
              Already have your Security Code? Click<Button type='link' onClick={resetPasswordInstead}>here</Button>
            </div>}
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Reset Password"
        open={resetPasswordVisible}
        footer={null}
        onCancel={closeResetPassword}
      >
        <Form onFinish={submitResetPass}>
          <Form.Item label="Username or email" name="username" rules={[{ required: true, message: "Username/email required"  }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Security Key" name="token" rules={[{ required: true, message:'Security key required'  }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="pwd"
            rules={[{ required: true, message: "Password required" }]}
              
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name = "confirm"
            label="Verify Password"
            rules={[ {required:true},
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('pwd') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password does not match!'));
                },
              }),
            ]}
            dependencies={["pwd"]}
          >
            <Input.Password />
          </Form.Item>
          <div color='red'>{errMsg}</div>
          <Form.Item>
            <Button type="primary" htmlType="submit">Submit New Password</Button>
            {<div>
              Need a Security Code? Click<Button type='link' onClick={forgotPasswordInstead}>here</Button>
            </div>}
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Change Password"
        open={changePassvisible}
        footer={null}
        onCancel={closeChangePass}
      >
        <Form onFinish={submitChangePass}>
          <Form.Item label="Username" name="username" rules={[{ required: true, message: "Username required"  }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Current Password" name="oldpwd" rules={[{ required: true, message: "Password required"  }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="newpwd"
            rules={[{ required: true, message: "Password required" }]}
              
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name = "confirm"
            label="Verify New Password"
            rules={[ {required:true},
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newpwd') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password does not match!'));
                },
              }),
            ]}
            dependencies={["newpwd"]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            {/*<Checkbox>Remember Me</Checkbox>*/}
            {/*<div>
              <a href="">Forgot Password?</a>
            </div>*/}
          </Form.Item>
          <div color='red'>{errMsg}</div>
          <Form.Item>
            <Button type="primary" htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={"Your Profile"}
        open={profileModalVisible}
        footer={null}
        onCancel={closeProfile}
      >
        <div> Welcome {usrname}! </div>
        <div>My Referral Code: {myReferralCode}</div>
        <Button onClick={showChangePassword}>Change Password</Button>
        <Button onClick={submitLogout}>Logout</Button>

        {emailVerificationStatus ?
          <NotificationSettings profileData={profileData}/>
          :
          <span>
            <div>Email Verification</div>
            <Button onClick={requestEmailVerification}>Send code to E-Mail</Button>
            <Form onFinish={submitEmailVerification}>
              <Form.Item label="Verification Code" name="token" rules={[{ required: true, message:'Verification Code required'  }]}>
                <Input />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">Submit</Button>
              </Form.Item>
            </Form>
            <div color='red'>{successMsg}</div>
          </span>
        }
        <div color='red'>{errMsg}</div>
      </Modal>
      <div class="header">
        <div class="bannercontainer">
          <div class = "banner side">
            {isDark ?
              <img src={goldLogoOnWhite} className="header-logo" alt="logo" align='left'/>:
              <img src={logo} className="header-logo" alt="logo" align='left'/>
            }
          </div>
          <div class= "banner main">
          <img src={textLogo} className='header-logo-text' alt="logo with text" />
            <h2>Welcome to SpoxMarket, the Stock Market for Sports</h2>
          </div>
          <div class="banner profile">
            {/*<Switch defaultChecked={systemPrefersDark} checked={isDark} checkedChildren="Dark" unCheckedChildren="Light" onChange={setIsDark} /> */}
            {!usrSignedIn &&
              <span>
                {isTabletOrMobile ?
                  <span>
                    <Button size="small" onClick={()=>showLogin("Log In")}>Login</Button>
                    <Button size="small" onClick={showSignUp}>Sign Up</Button>
                  </span>
                :
                  <span>
                    <Button style={{width: '80px'}} onClick={()=>showLogin("Log In")}>Login</Button>
                    <Button style={{width: '80px'}} onClick={showSignUp}>Sign Up</Button>
                  </span>
                }
              </span>}
            {usrSignedIn && 
            <span>
              {isTabletOrMobile ?
              <span>
                <div>{usrname}</div>
                <Button icon={<UserOutlined />} onClick={showProfile} size="small"></Button>
              </span> :
              <span>
                <div>Welcome {usrname}! </div>
                <div><Button icon={<UserOutlined />} onClick={showProfile}>Profile</Button></div>
                <Button onClick={submitLogout}>Logout</Button>
              </span>}
            </span>}
          </div>
        </div>
        <div>
      <Menu onClick={handleClick} mode="horizontal" defaultSelectedKeys="Home" selectedKeys={frame}>
        <Menu.Item key="Home">Home</Menu.Item>
        <SubMenu key="Market" title="Market" onTitleClick={handleTitleClick}>
          {/*<Menu.Item key="GlobalMarket"> Global</Menu.Item>*/}
          <Menu.Item key="NFLMarket">2024 NFL</Menu.Item>
          <Menu.Item key="Restricted">Restricted</Menu.Item>
          <Menu.Item key="NCAAMMarket">NCAAM</Menu.Item>
          <Menu.Item key="NFLMarket2023">2023 NFL</Menu.Item>
          <Menu.Item key="NFLPlayoffMarket2023">2023 NFL Playoffs</Menu.Item>
          {usrSignedIn && isAdmin && <Menu.Item key="RestrictNFLMarket">Restricted Reg</Menu.Item>}
          {usrSignedIn && isAdmin && <Menu.Item key="RestrictNFLPlayoffMarket">Restricted Post</Menu.Item>}
          {/*<Menu.Item key="NBAMarket"> NBA </Menu.Item> */}
        </SubMenu>
        {usrSignedIn && (
          <SubMenu title="Portfolio" key="Portfolio" onTitleClick={handleTitleClick}>
            <Menu.Item key="CreatePortfolio"> Create Portfolio </Menu.Item>
            <Menu.Item key="AddPortToLeague"> Add Portfolio To League </Menu.Item>
            <Menu.Item key="MyPortfolios"> My Portfolios </Menu.Item>
          </SubMenu>
        )}

        {usrSignedIn && (
          <SubMenu title="League" key="League" onTitleClick={handleTitleClick}>
            <Menu.Item key="CreateLeague"> Create a League </Menu.Item>
            <Menu.Item key="JoinLeague"> Join a League </Menu.Item>
            <Menu.Item key="MyLeagues"> My Leagues</Menu.Item>
            {/*<Menu.Item key="PublicLeages"> Public Leagues</Menu.Item> */}
          </SubMenu>
        )}



        {/*<Menu.Item key="News">News</Menu.Item> */}
        {usrSignedIn && isAdmin && (
        <SubMenu title="Admin" key="Admin" onTitleClick={handleTitleClick}>
          <Menu.Item key="AdminUniversal">Universal</Menu.Item>
          <Menu.Item key="AdminStock">Stock</Menu.Item>
          <Menu.Item key="AdminPost">Post</Menu.Item>
          <Menu.Item key="AdminEmail">Email</Menu.Item>
          <Menu.Item key="AdminGame">Game</Menu.Item>
        </SubMenu>)}

      </Menu>
      </div>
      </div>


      <div class="row">
        <Body table={frame}></Body>
      </div>
    </div>
  );
}

export default App;
