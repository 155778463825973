import { useContext } from "react";
import AuthContext from "../contexts/Authentication";
import axios from "../axios";


const useRefreshToken = () => {
    const {setAuth} = useContext(AuthContext);

    const refresh = async ( ) => {
        try{
            //console.log ("refresh token func");
            const response = await axios.get('/refreshToken',{
                withCredentials: true
            });
            //console.log("response of refresh");
            //console.log(response)
            setAuth ( prev => {
                console.log("setting new acces tok");
                //console.log(JSON.stringify(prev));
                //console.log(response.data.accessToken);
                return {
                    ...prev, 
                    role: response.data.role,
                    access:response.data.accessToken}
            });
            //return {'user':response.data.username, 'role': response.data.role};
            return {'user':response.data.username,'role':response.data.role, 'access':response.data.accessToken,'referralCode':response.data.referralCode};
        }
        catch (err) {
            console.log("error in refresh");
            console.log(err);
            setAuth ({});

            return {};
        }
    }
    return refresh;
};

export default useRefreshToken;