import React from "react";


import CreateLeaguePage from "./CreateLeaguePage";
import JoinLeaguePage from "./JoinLeaguePage";
import MyLeaguesPage from "./MyLeaguesPage";
import LeaguePage from "./LeaguePage";


const League = (props) => {





  const componentMap = {
    "": null,
    CreateLeague: <CreateLeaguePage leagueCallback={props.leagueCallback}/>,
    JoinLeague: <JoinLeaguePage leagueCallback={props.leagueCallback}/>,
    MyLeagues: <MyLeaguesPage leagueCallback={props.leagueCallback} />,
    League:
      <span>
        <p>Select A League First:</p>
        <MyLeaguesPage leagueCallback={props.leagueCallback} />
      </span>,
    Leagues: <LeaguePage leagueInfo={props.leagueInfo} portCallback={props.portCallback}/>
  };

  return (
    <div>
    {componentMap[props.type]}
  </div>
  )
}


export default League;
