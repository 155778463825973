import React, { Component } from "react";

import { Menu } from "antd";
import NewsBody from "./NewsBody";

class News extends Component {
  constructor() {
    super();

    this.state = {
      Frame: "All"
    };
  }

  handleClick = (e) => {
    //console.log("click ", e);
    console.log("handle click News");
    console.log(e);
    console.log(e.key);
    this.setState({
      Frame: e.key
    });
  };

  render() {
    return (
      <div>
        <Menu
          onClick={this.handleClick}
          mode="horizontal"
          defaultSelectedKeys="Home"
        >
          <Menu.Item key="All">All</Menu.Item>
          <Menu.Item key="NFL">NFL</Menu.Item>
          <Menu.Item key="NBA">NBA</Menu.Item>
        </Menu>
        <NewsBody table={this.state.Frame}></NewsBody>
      </div>
    );
  }
}

export default News;
