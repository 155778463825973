import React, { createContext, useState } from "react";
const FrameContext = createContext({});
export const FrameProvider = ({children}) => {
  const [frame, setFrame] = useState({});
  return (
    <FrameContext.Provider value={{frame, setFrame}}>
      {children}
    </FrameContext.Provider>
  );
};

export default FrameContext;