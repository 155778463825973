import React from "react";



import CreatePortfolio from "./CreatePortfolio";
import AddPortToLeague from "./AddPortToLeague";
import MyPortfoliosPage from "./MyPortfoliosPage";
import PortfolioPage from "./PortfolioPage";


const Portfolio = (props) => {



  var componentMap = {
    "": null,
    CreatePortfolio: <CreatePortfolio portCallback={props.portCallback} />,
    AddPortToLeague: <AddPortToLeague portCallback={props.portCallback}/>,
    MyPortfolios: <MyPortfoliosPage portCallback={props.portCallback} />,
    Portfolio:
      <span>
        <p>Select A Portfolio First:</p>
        <MyPortfoliosPage portCallback={props.portCallback} />
      </span>,
    Portfolios: <PortfolioPage portInfo={props.portInfo} leagueCallback={props.leagueCallback}/>
  };

  return (
    <div>
    {componentMap[props.type]}
  </div>
  )
}


export default Portfolio;
