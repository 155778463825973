import React from "react";
import "antd/dist/antd.min.css";
import axios from './axios';
import {Table, Button, Input, Space, Tag} from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { useState, useCallback, useEffect, useRef} from "react";

import StockPerformance from "./StockPerformance";

import {positionMap} from './CONSTANTS';

import { useMediaQuery } from 'react-responsive';

const NCAABBtable = (props) => {


  //const isTabletOrMobile = useMediaQuery({maxWidth:640});
  const isLarge = useMediaQuery({minWidth:900});
  //const isMobile = useMediaQuery({maxWidth:500});
  const isXSmall = useMediaQuery({maxWidth:575});

  const [tablePagination,setTablePagination] = useState({current:1, pageSize:50});
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm) => {
    console.log("handle search");
    console.log(selectedKeys);
    confirm();
  };
  const handleReset = (clearFilters,setSelectedKeys,confirm) => {
    console.log("handle reset ")
    clearFilters();
    setSelectedKeys([]);
    confirm();
  };
  /*const nameSearchProps = {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search Name`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters,setSelectedKeys,confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record['name'].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  }; */

  const teamSearchProps = {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search Name`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters,setSelectedKeys,confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record['name'].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  };

  const goToStockChart = (e,record) => {
    console.log(record.ent_id);
    props.showStockPerformance(record.equity_id,record.name);
  }

  const buyColumn = {
    title: "Buy Shares",
    dataIndex: "key",
    key: "key",
    responsive: ['sm'],
    render : (text,record) => (
      !record.restricted ? <Button onClick={(e) => {props.purchaseCallback(e,record)}} >Buy Shares</Button> : <span></span>
    )
  };
  const buyColumnMobile = {
    title: "Buy",
    dataIndex: "key",
    key: "key",
    responsive: ['xs'],
    render : (text,record) => (
      !record.restricted ? <Button onClick={(e) => {props.purchaseCallback(e,record)}} >Buy</Button> : <span></span>
    )
  }
  var columns = [
    /*{
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ['descend','ascend'],
      width: isLarge ? 'auto' :  '15%', 
      sorter:  (a, b) => {
        if (a.name>b.name){
          return -1;
        }
        else {
          return 1;
        }
      },
      ...nameSearchProps
    },*/
    /*{
      title: isLarge ? "Position" : "Pos",
      dataIndex: "position",
      key: "position",
      //responsive: ['sm'],
      width: isLarge ? 'auto' :  '10%',
      sortDirections: ['descend','ascend'],
      sorter:  isLarge ? (a, b) => {
        if (a.position>b.position){
          return 1;
        }
        else {
          return -1;
        }
      } : null,
      filters: basketballposFilters,
      onFilter: (value, record) => record.position===value
    }, */ //dont need position yet as Teams only
    {
      title: "Seed",
      dataIndex: "seed",
      key: "seed",
      width: '50px',
      sortDirections: ['descend','ascend'],
      sorter: isLarge ? (a, b) => {
        if (a.seed>b.seed){
          return -1;
        }
        else {
          return 1;
        }
      } : null
    },
    {
      title: !isXSmall ? "Team" : "Tm",
      dataIndex: "name",
      key: "team",
      sortDirections: ['descend','ascend'],
      width: isLarge ? 'auto' :  '15%',
      sorter: isLarge ? (a, b) => {
        if (a.team>b.team){
          return -1;
        }
        else {
          return 1;
        }
      } : null,
      ...teamSearchProps

    },
    {
      title: "",
      responsive: ['md'],
      render : (record) => (
        <Button onClick={(e) => {goToStockChart(e,record)}}>See Chart</Button>
      )
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: isLarge ? 'auto' :  '15%',
      defaultSortOrder: 'descend',
      sortDirections: ['descend','ascend'],
      sorter:  (a, b) => a.price - b.price
    },
    {
      title: "Weekly Change",
      dataIndex: "change",
      key: "change",
      responsive: props.transact ? ['sm'] : null,
      sortDirections: ['descend','ascend'],
      sorter:  (a, b) => a.change - b.change,
      render : (text,record) => (
        record.change ? (record.change>0 ? <Tag color='green'>{text}%</Tag> : (record.change<0 ? <Tag color='red'>{text}%</Tag> : <Tag>{text}%</Tag>)) : <span>-</span>

      )
    },
    {
      title: "YTD Change",
      dataIndex: "YTDchange",
      key: "YTDchange",
      responsive: ['sm'], //small appears at 575px
      sortDirections: ['descend','ascend'],
      sorter:  (a, b) => a.YTDchange - b.YTDchange,
      render : (text,record) => (
        record.YTDchange ? (record.YTDchange>0 ? <Tag color='green'>{text}%</Tag> : (record.YTDchange<0 ? <Tag color='red'>{text}%</Tag> : <Tag>{text}%</Tag>)) : <span>-</span>

      )
    },
    {
      title: "Dividend",
      dataIndex: "dividend",
      key: "dividend",
      sortDirections: ['descend','ascend'],
      sorter:  (a, b) => a.dividend - b.dividend,
      responsive: ['sm']
    },
    {
      title: "Proj. Earnings",
      dataIndex: "growth",
      key: "growth",
      sortDirections: ['descend','ascend'],
      sorter:  (a, b) => a.growth - b.growth,
      responsive: ['sm']
    }

  ];


  const [isErr,setIsErr] = useState(false);
  const [loading,setLoading] = useState(true);
  const [marketData,setMarketData] = useState([]);
  const [tableColumns,setTableColumns] = useState(columns);
  //console.log(props);



  const fetchData =  useCallback(async() => {

    var data = [];
    setMarketData([]);
    try {
      console.log("get ncaa march madness market response");
      var url;
      console.log(props.type);
      if (props.type==="Playoffs") {
        //console.log("here in playoffs");
        url = "NCAAMarchMadness";
      }
      else {
        url = "NCAAM";
      }
      console.log(url);
      const res = await axios.get('/getmarket/'+ url);

      console.log(res.data); //debugger
      for (let i=0; i<res.data.length; ++i){
        var obj;
        var record = res.data[i];
        var YTDchange;
        var change;
        //console.log(record);
        if (record?.prevprice){
          //console.log(record.prevprice)
          change = (100*(Number(record.price)-Number(record.prevprice))/Number(record.prevprice)).toFixed(2);
        }
        else {
          change = null;
        }
        if (record?.origprice){
          YTDchange = (100*(record.price-record.origprice)/Number(record.origprice)).toFixed(2);
        }
        else {
          YTDchange = null;
        }
       // console.log(change)
        if (record?.player_id){
          obj = {
            name: record.fname + " " + record.lname,
            position: positionMap[record.position_id],
            team: record.city + " " + record.nickname,
            teamabbrev: record.abbrev,
            rookie: record.rookie,
            price: record.price,
            dividend: record.dividend,
            equity_id: record.equity_id,
            ent_id: record.player_id,
            key: record.equity_id,
            restricted: record.restricted,
            change: change,
            YTDchange: YTDchange,
            growth: (record.price - record.dividend).toFixed(2)
          }
        }
        else {
          //console.log(record);
          obj = {
            name: isLarge ? record.city + " " + record.nickname : record.abbrev,
            position: "TEAM",
            team: record.city + " " + record.nickname,
            seed: record.ranking,
            teamabbrev: record.abbrev,
            price: record.price,
            dividend: record.dividend,
            equity_id: record.equity_id,
            ent_id: record.team_id,
            key: record.equity_id,
            restricted: record.restricted,
            change: change,
            YTDchange: YTDchange,
            growth: (record.price - record.dividend).toFixed(2)
          }

        }
        if (obj.rookie){
          obj.name = obj.name + "(R)";
        }
        if (obj.restricted){
          obj.name = obj.name + "*";
        }
        data.push(obj)
      }
      //data.sort()
      setLoading(false);
      console.log(data);
      setMarketData(data);

    
    }
    catch (err) {
      console.log(err);
      setIsErr(true);
      setLoading(false);
    }
  },[props.type,isLarge]);

  useEffect( () => {
    console.log("here in ncaab table use effect");
    if (props.transact){
      columns.push(buyColumn);
      columns.push(buyColumnMobile);
    }

    setTableColumns(columns);
    fetchData();
  }, [fetchData,props.transact,props.type]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect( () => {
    setTableColumns(columns);
  }, [isXSmall,isLarge]);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableChange = (pagination) => {
    console.log("handle table change");
    console.log(pagination);
    setTablePagination(pagination);
  }

  const expandableRows = {
    expandedRowRender: (record) => (
      <span>
        <div>
         {props.transact ? <span>Weekly Change: {record.change ? (record.change>0 ? <Tag color='green'>{record.change}%</Tag> : (record.change<0 ? <Tag color='red'>{record.change}%</Tag> : <Tag>{record.change}%</Tag>)) : <span>-</span>}</span> : <span></span>}
         YTD Change: {record.YTDchange ? (record.YTDchange>0 ? <Tag color='green'>{record.YTDchange}%</Tag> : (record.YTDchange<0 ? <Tag color='red'>{record.YTDchange}%</Tag> : <Tag>{record.YTDchange}%</Tag>)) : <span>-</span>}
        </div>
        <div>
          Dividend: ${record.dividend}  Proj. Earnings: ${record.growth}
        </div>
        {/*<div>
        <Button onClick={(e) => {goToStockChart(e,record)}}>See Chart</Button>
    </div>*/}
        {<div>
          <StockPerformance eqID={record.equity_id} name={record.name}  mobile={true} hist={props.hist}/>
        </div>}
      </span>
    ),
    rowExpandable: (record) => isXSmall
  }

    return (
      <div           >
        {!isErr &&
          <span><text>Trading is halted for players/teams indicated with a *</text>
        <Table
          dataSource={marketData}
          columns={tableColumns}
          loading={loading}
          pagination={tablePagination}
          onChange={handleTableChange}
          expandable={isXSmall ? expandableRows: null}
          size= {isLarge ? "large" : "small"}

        /></span>}
        {isErr &&
        <p>Server Error. Failed to get market data.</p>}
      </div>
    );

}


export default NCAABBtable