import {Form, Table, Button, Select} from "antd";
import React, { useContext, useEffect,useState, useCallback} from "react";
import LeaguePage from "./LeaguePage.js";
import useGetMyPortfolios from "./hooks/useGetMyPortfolios.js";
import FrameContext from "./contexts/FrameContext.js";
import useAxiosPrivate from "./hooks/useAxiosPrivate.js";

//import axios from 'axios';

import { useMediaQuery } from 'react-responsive';
import { timeframeToString } from "./CONSTANTS.js";

const MyPortfoliosPage = (props) => {

  const axiosPrivate = useAxiosPrivate();

  const isXSmall = useMediaQuery({maxWidth:575});

  const {setFrame} = useContext(FrameContext);
  const getMyPortfolios = useGetMyPortfolios();
  const [tableData,setTableData] = useState([{}]);

  const [isErr,setIsErr] = useState(false);
  const [isSubmitting,setIsSubmitting] = useState(false);
  //const [isLeagueSelected,setIsLeagueSelected] = useState(false);
  const isLeagueSelected = false;

  const [copyPort,setCopyPort] = useState(false);
  const [copyErr,setCopyErr] = useState(false);
  const [copyErrMsg,setCopyErrMsg] = useState("");
  const [copyOk,setCopyOk] = useState(false);
  const [copyData,setCopyData] = useState([{}]);
  const [destPort,setDestPort] = useState(-1);

  const [copyOkMsg,setCopyOkMsg]= useState("");
  const [copyGoodToExecute,setCopyGoodToExecute] = useState(false);

  const [copyComplete,setCopyComplete] = useState(false);
  const [copyExecuteError,setCopyExecuteError] = useState(false);

  const [portNames,setPortNames] = useState([]);

  const copyPortString = {
    true: "Don't",
    false: ""
  };

  const [anyDeleteable,setAnyDeleteable] = useState(false);

  const getMyPortfolioInfo = useCallback(async() => {
    try {
      setIsSubmitting(true);
      const data = await getMyPortfolios();
      console.log(data);
      console.log("User has " + data.length + " portfolios");
      setTableData(data.sort( (a,b) => (b.timeframe - a.timeframe)));
      const portOptions = data.map( (port) => (
        {
          value: port.port_id,
          label: port.port_name,
          league_count: port.league_count,
          timeframe: port.timeframe,
          year: port.port_year
          //port_type: port.port_type
        }

      ))
      const anyDel = portOptions.some(item=> {
        return item.league_count <=0;
      });
      setAnyDeleteable(anyDel);
      console.log(portOptions);
      setPortNames(portOptions);
      setIsSubmitting(false);
    }
    catch (err) {
      console.log(err);
      setIsErr(true);
      setIsSubmitting(false);
      return err;
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect( () => {
    getMyPortfolioInfo();
  }, [getMyPortfolioInfo]); 



  const gotoPort = (e,record) => {
    console.log("setting current portfolio");
    console.log(e);
    console.log(record);
    props.portCallback(record);

  }

  const deletePort = async(e,record) => {
    console.log("deleting portfolio");
    console.log(record);
    const data = {
      port_id: record.port_id
    }
    try {
      await axiosPrivate.put('/portfolio/delete',data);

      getMyPortfolioInfo();
    }
    catch (err) {

    }
  }

  const switchCopyPort = () => {
    const copyPortSave = copyPort;
    resetCopyPort();
    setCopyPort(!copyPortSave);
  }

  const resetCopyPort = () => {
    setCopyErr(false);
    setCopyExecuteError(false);
    setCopyComplete(false);
    setCopyData({});
    setCopyPort(true);
    setCopyErr(false);
    setCopyOkMsg("");
    setDestPort(-1);
    setCopyOk(false);
    setCopyGoodToExecute(false);
    setCopyErrMsg("");
  }

  var columns = [
    {
      title: "Portfolio Name",
      dataIndex: "port_name",
      key: "name"
    },
    {
      title: "Worth",
      dataIndex: "worth",
      key: "worth",
      render : (text) => (
        "$"+ text
      )
    },
    {
      title: "Go to Portfolio",
      dataIndex: "button",
      key: "button",
      render : (text,record) => (
        <Button onClick={(e) => {gotoPort(e,record)}} >Go To Portfolio</Button>
      )
    },
    {
      title: "Season",
      dataIndex: "timeframe",
      render: (text,record) => (
        timeframeToString[record.timeframe]
      ),
    }
  ]

  if (anyDeleteable){
    columns.push(    {
      title: "Delete Portfolio",
      dataIndex: "league_count",
      key: "league_count",
      responsive: ['sm'],
      render : (text,record) => (
        record.league_count<=0 ? <Button onClick={(e) => {deletePort(e,record)}} >Delete Portfolio</Button> : <span></span>
      )
    })
  }

  const copyColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title:'Quantity',
      dataIndex: "quant",
      key: 'quantity'
    }
  ]

  const createPortfolio = (e) => {
    setFrame("CreatePortfolio");
  }

  const executePortCopy = async(e) => {

    console.log("Executing portfolio copy");
    console.log(copyData);

    const request= {
      data: copyData,
      port_id: destPort
    };

    console.log(destPort);
    try {
      await axiosPrivate.post('/portfolio/tradeMultiple',request)

      setCopyComplete(true);
    }
    catch (err) {
      setCopyExecuteError(true);
    }
  }


  const getPortDiffs = async(e) => {
    console.log("Getting portfolio diffs");
    console.log(e)
    if (e.port_dest.key===e.port_src.key){
      console.log("Cannot copy to same portfolio")
      setCopyErr(true)
      setCopyErrMsg("Portfolios are the same, cannot copy.")
    }
    const destinationPort = e.port_dest.key;
    const srcPort  = e.port_src.key;
    const destData = {port_id:destinationPort};
    const data = {portfolios:[destinationPort, srcPort]};
    const destPortName = e.port_dest.port_name;
    try {
      const settingsResponse = await axiosPrivate.post('/portfolio/getLeagueSettings',destData)
      //console.log(settingsResponse);
      const settings = settingsResponse.data[0];
      const leagueName = settings.league_name;
      console.log(settings);
      if (!settings.tradeable){
        console.log(destPortName + " in league " + leagueName + " is not currently tradeable.")
        setCopyErr(true)
        setCopyErrMsg(destPortName + " in league " + leagueName + " is not currently tradeable.")
        return;
      }
      else if (!settings.inseason && settings.started){
        console.log(destPortName + " in league " + leagueName + " does not allow inseason trading.")
        setCopyErr(true)
        setCopyErrMsg(destPortName + " in league " + leagueName + " does not allow inseason trading.")
        return;
      }
      else{
        const response = await axiosPrivate.post('/portfolio/getMultData',data);
        console.log(response);
        var portDiffs = {};
        for (var i=0; i<response.data.length;i++){
          var record = response.data[i];
          var ent_id;
          var equity_id;
          var name;
          if (record?.player_id){
            name = record.fname + " " + record.lname;
            ent_id = record.player_id;
            equity_id = record.equity_id;
          }
          else{
            name = record.city + " " + record.nickname;
            ent_id = record.team_id;
            equity_id = record.equity_id;
          }
          if (!(equity_id in portDiffs)){
            portDiffs[equity_id] = {
              ent_id: ent_id,
              equity_id: equity_id,
              name: name,
              quant:0,
              startQuant: 0,
              price: record.price,
              restricted: record.restricted
            };
          }
          if (record.port_id===destinationPort){
            portDiffs[equity_id].quant = portDiffs[equity_id].quant - record.quantity;
            portDiffs[equity_id].startQuant = record.quantity;
          }
          else { //record.port_id===srcPort
            portDiffs[equity_id].quant = portDiffs[equity_id].quant + record.quantity;

            if (!settings.shorting && record.quantity<0){
              const msg = "Cannot copy portfolio because shorting is not allowed for the destination league.";
              console.log(msg);
              setCopyErr(true);
              setCopyErrMsg(msg);
              return;
            }
          }
        }
        console.log("port diffs")
        console.log(portDiffs);
        const stocks = Object.values(portDiffs)
        var sharecost = 0.0;
        var totshares = 0;
        var totmoney = 0.0;
        var avail_cost = 0.0;
        for (i=0; i<stocks.length; i++){
          console.log(stocks[i])
          if (stocks[i].restricted){
            const msg = "Cannot copy portfolio because trading is restricted for " + name + ".";
            console.log(msg);
            setCopyErr(true);
            setCopyErrMsg(msg);
            return;
          }
          else {
            const purchase_quant = stocks[i].quant;
            const price = stocks[i].price;
            const thiscost = price * purchase_quant;
            sharecost += thiscost;
            totmoney += Math.abs(thiscost);
            totshares += Math.abs(purchase_quant);
            var thisavailcost = 0.0;
            const orig_quant = stocks[i].startQuant;
            if (purchase_quant>0){ //buying
              if (orig_quant>=0){ //already have some shares and buying more
                thisavailcost = purchase_quant*price;
              }
              else { //previously shorting
                const coverQuant = Math.min(Math.abs(orig_quant),purchase_quant); //either covering all short or only what is purchased
                thisavailcost = -coverQuant*price;
                thisavailcost += (purchase_quant-coverQuant)*price; //whatever stocks we buy into positive deduct from avail_cash
              }
            }
            else { //selling
              if (orig_quant<=0) { //selling while short or at 0
                thisavailcost = Math.abs(purchase_quant)*price;
              }
              else {
                //selling while long
                const coverQuant = Math.min(orig_quant,Math.abs(purchase_quant)); //either covering all long or only what is purchased
                thisavailcost = -coverQuant*price;
                thisavailcost += (purchase_quant-coverQuant)*price; //whatever stocks we sell into negative deduct from avail_cash
              }

            }
            avail_cost += thisavailcost;
          }
        }
        var trans_cost;
        if ((!settings.started && settings.delay) || settings.trans_cost_type===0){
          trans_cost = 0.0;
        }
        else {
          trans_cost = Number(settings.transaction_cost)*stocks.length + Number(settings.transaction_cost)*totmoney/100.0 + Number(settings.transaction_cost)*totshares;
        }

        const totalCost = trans_cost + sharecost;
        const totalAvailCost = trans_cost + avail_cost;
        console.log(settings.available_cash);
        if (totalCost<=Number(settings.cash) && totalAvailCost<=Number(settings.cash_available)){

          setCopyGoodToExecute(true);
          if (totalCost>0){
            setCopyOkMsg("Current cash amount is $"+ settings.cash+ ". Current available cash amount is $"+ settings.cash_available +
            ". \nTotal cost is $" + totalCost.toFixed(2) + ". Total change to available cash is $" + -1*totalAvailCost.toFixed(2));
          }
          else {
            setCopyOkMsg("Total proceeds from sales would be $" + Math.abs(totalCost).toFixed(2) + ".");
          }
          setDestPort(destinationPort);

        }
        else{
          setCopyGoodToExecute(false);
          if (totalCost>Number(settings.cash)){
            setCopyOkMsg("Total cost is $" + totalCost.toFixed(2) + ". Cannot execute trades as this exceeds your cash level.")
          }
          else {
            setCopyOkMsg("Total available cash deduction is $" + totalAvailCost.toFixed(2) + ". Cannot execute trades as this exceeds your available cash.")
          }


        }
        setCopyData(stocks);
        setCopyOk(true);

      }

    }
    catch (err){

    }
  }

  /*const layout = {
    labelCol: {span:6},
    wrapperCol: {span:14}
  }*/

  const expandableRows = {
    expandedRowRender: (record) => (
      <span>
        <div>{record.league_count<=0 ? <Button onClick={(e) => {deletePort(e,record)}} >Delete Portfolio</Button> : <span></span>}</div>
      </span>
    ),
    rowExpandable: (record) => isXSmall && record.league_count<=0
  }

  return (

    <div>
      { !isLeagueSelected &&
      <span>
      {!isErr &&
        <span>
          <Table
            title = {() => "Portfolio Results"}
            dataSource={tableData}
            rowKey="port_id"
            columns={columns}
            loading={isSubmitting}
            size="middle"
            expandable={anyDeleteable ? expandableRows: null}
          />
          {tableData.length>1 && copyComplete && <span>
            <div>Portfolio Successfully copied!</div>
            <Button onClick={resetCopyPort}>Copy Another?</Button>
            </span>}
          {copyExecuteError && <span>
              <div>Error executing copy. Try to manually copy portfolio one stock at a time.</div>
              <Button onClick={resetCopyPort}>Try Again?</Button>
            </span>}
          {!copyComplete &&  !copyExecuteError &&
            <span><Button onClick={switchCopyPort}>{copyPortString[copyPort]} Copy Portfolio </Button>
          {copyPort && 
          <span>
              <div>You can use this tool to copy the stocks in one portfolio into another.</div>
              <Form  onFinish={getPortDiffs}>
                <Form.Item label="Source Portfolio" name="port_src" rules={[{ required: true, message: "Must select a source portfolio." }]}>
                  <Select labelInValue={true} placeholder="Please choose a portfolio" options={portNames}>
                  </Select>
                </Form.Item>

                <Form.Item label="Destination Portfolio" name="port_dest" rules={[{ required: true, message: "Must select a destination." }]}>
                  <Select labelInValue={true} placeholder="Please choose a different portfolio" options={portNames}>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" >Check Portfolio Copy</Button>
                </Form.Item>
              </Form>
            {copyErr && <span>{copyErrMsg}</span>}
          </span> }
          {copyOk && <span>
            <Table
            title = {() => "Copy Portfolio Results"}
            dataSource={copyData}
            rowKey="equity_id"
            columns={copyColumns}
            size="middle"
            />
            <div><pre>{copyOkMsg}</pre></div>
            {copyGoodToExecute && <span>
              <Button type="primary" htmlType="submit" onClick={executePortCopy}>Execute Trades</Button>
            </span>}
          </span>

          }
        </span>}
      {isErr && <div><p>Failed to retrieve your portfolios.</p>
        <Form>
          <Form.Item>
            <Button  onClick={getMyPortfolioInfo} >Try Again?</Button>
          </Form.Item>
        </Form></div>}
      </span>}
      </span>
    }
    {(tableData.length===0) &&
    <span>
      <div>No portfolios. Please create one.</div>
      <Button  onClick={createPortfolio} >Create Portfolio</Button>
    </span>}
    {isLeagueSelected  && <LeaguePage />}
    </div>
  )
}

export default MyPortfoliosPage;