import React from "react";

import { Menu } from "antd";

import PortBody from "./PortBody";
import { useState, useEffect, useCallback } from "react";
import useAxiosPrivate from "./hooks/useAxiosPrivate.js";


const PortfolioPage = (props) => {


  const [portKey,setPortKey] = useState("Home");

  const handleClick = (e) => {
    //console.log("click ", e);
    console.log("handle click in Portfolio Page");
    console.log(e.key);
    setPortKey(e.key);
  };

  const axiosPrivate = useAxiosPrivate();

  const [displayCash,setDisplayCash] = useState(false);
  const [cash,setCash] = useState(0);
  const [spendCash,setSpendCash] = useState(0);
  const [value,setValue] = useState(0);

  const portIsCurrent = props.portInfo.port_timeframe===7;

  const getValue = useCallback(() => {

    const dataSend = {port_id:props.portInfo.port_id};
    console.log(dataSend);
    axiosPrivate.post('/portfolio/getValue',dataSend)
    .then((res) => {
      console.log("getportfolio value data response");
      console.log(res.data); //debugger

      const cash = Number(res.data[0].cash);
      console.log(cash)
      setSpendCash(Number(res.data[0].cash_available).toFixed(2));
      setCash(cash.toFixed(2));
      setValue((Number(res.data[0].value)+cash).toFixed(2));
      setDisplayCash(true);
      //console.log(value)

    })
  },[axiosPrivate,props.portInfo.port_id])

  useEffect( () => {
    console.log("loading portfolio page")
    //console.log(props.portInfo)
      getValue();
    }, [getValue]); //empty dependency array means function only called once on mount

  const updateMoney = () => {
    getValue();
  }




    return (
      <div>
        <div>Current Portfolio: {props.portInfo.port_name}</div>
        {displayCash &&
          <span>
            <p>Cash: ${cash}<br />
            Spending Cash: ${spendCash}<br />
            Total Worth: ${value}</p>
          </span>
        }
        <div></div>
        <Menu
          onClick={handleClick}
          mode="horizontal"
          defaultSelectedKeys="Home"
        >
          <Menu.Item key="Home">Portfolio Home</Menu.Item>
          {portIsCurrent && <Menu.Item key="Trade">Buy/Sell</Menu.Item>}
          <Menu.Item key="Performance">Performance</Menu.Item>
          {props.portInfo.port_format===0 && <Menu.Item key="Transactions">Transactions</Menu.Item>}
          <Menu.Item key="Settings">Settings</Menu.Item>
        </Menu>
        <PortBody table={portKey} portInfo={props.portInfo} worth={value} tradeCallback={updateMoney} leagueCallback={props.leagueCallback}></PortBody>
      </div>
    );
}

export default PortfolioPage;
