
import LeagueTable from "./LeagueTable";

const LeagueHome = (props) => {



    return (
        <span>
            <LeagueTable leagueInfo={props.leagueInfo} />
        </span>
    )

}


export default LeagueHome