import {Form, Select, Button} from "antd";
import React, { useState, useContext, useEffect, useCallback } from "react";
import useAxiosPrivate from "./hooks/useAxiosPrivate.js";
import useGetMyLeagues from "./hooks/useGetMyLeagues.js";
import useGetMyPortfolios from "./hooks/useGetMyPortfolios.js";
import FrameContext from "./contexts/FrameContext.js";


const AddPortToLeague = (props) => {


  const axiosPrivate = useAxiosPrivate();

 
  const [isCompleted,setIsCompleted] = useState(false);

  const {setFrame} = useContext(FrameContext);

  const getMyLeagues = useGetMyLeagues();
  const [leagueNames,setLeagueNames] = useState([]);
  const getMyPortfolios = useGetMyPortfolios();
  const [portNames,setPortNames] = useState([]);

  const [isErr,setIsErr] = useState(false);
  const [errMsg,setErrMsg] = useState("");
  const [isLeagueSubmitting,setIsLeagueSubmitting] = useState(false);
  const [isPortSubmitting,setIsPortSubmitting] = useState(false);
  const [isSubmitting,setIsSubmitting] = useState(false);

  const [portRecord,setPortRecord] = useState({});


  const getMyLeagueInfo = useCallback(async() => {
    try {
      setIsLeagueSubmitting(true);
      const myLeagues = await getMyLeagues();
      console.log(myLeagues);
      console.log(myLeagues.length);
      const leagueOptions = myLeagues.map( (league) => (
        {
          value: league.lg_id,
          label: league.league_name
        }
      ))
      console.log(leagueOptions);
      setLeagueNames(leagueOptions);
      setIsLeagueSubmitting(false);
    }
    catch (err) {
      console.log(err);
      setIsErr(true);
      setIsLeagueSubmitting(false);
      return err;
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getMyPortInfo = useCallback(async() => {
    try {
      console.log ("Getting my portfolios");
      setIsPortSubmitting(true);
      const myPorts = await getMyPortfolios();
      console.log(myPorts);
      const portOptions = myPorts.map( (port) => (
        {
          value: port.port_id,
          label: port.port_name,
          portInfo: port

        }
      ))
      console.log(portOptions);
      setPortNames(portOptions);
      setIsPortSubmitting(false);
    }
    catch (err) {
      console.log(err);
      setIsErr(true);
      setIsPortSubmitting(false);
      return err;
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect( () => {
    getMyLeagueInfo();
    getMyPortInfo();
  }, [getMyLeagueInfo,getMyPortInfo]); //empty dependency array means function only called once on mount



  const portjoinLeagueClick = async (e) => {
    console.log("here in port join league submit");
    console.log(e);

    var data = {
      port_id:e.port_id.value,
      league_id:e.league_id.value
    };

  
    console.log(data);
    console.log(e.port_id.portInfo);

    setPortRecord(e.port_id.portInfo);

    try {
      setIsSubmitting(true);
      const response = await axiosPrivate.post('/portfolio/addToLeague',data);
      console.log(response);
      setIsCompleted(true);
      setIsSubmitting(false);
      setIsErr(false);
      console.log("league create successful");

    }
    catch(err){
      console.log(err);
      console.log(err.message);
      console.log(err.response);
      setErrMsg(err.response.data.message);
      setIsErr(true);
      setIsSubmitting(false);
    }
  }

  const goToThisPort = () => {
    props.portCallback(portRecord);
  }


 const tryAgain = () => {
    setIsErr(false);
 }



  const createPortfolio = (e) => {
    setFrame("CreatePortfolio");
  }

  const layout = {
    labelCol: {span:6},
    wrapperCol: {span:14}
  }


  return (

    <div>
      <div>Instead of creating a new Portfolio for each league you join, you can enter the same portfolio into multiple leagues. Think of it like entering the same
        March Madness bracket into multiple groups that you are a part of.  You just need to make sure that the league you are adding to has the same settings for
        trading as the league(s) that your Portfolio is already a part of.
      </div>
      {(!isLeagueSubmitting && !isPortSubmitting && !isSubmitting && !isCompleted && !isErr) && <span>
        {(portNames.length>0) &&
        <span>
          <Form {...layout}  onFinish={portjoinLeagueClick}>
            <Form.Item label="Portfolio" name="port_id" rules={[{ required: true, message: "Must select a portfolio." }]}>
              <Select labelInValue={true} placeholder="Please choose a portfolio" options={portNames}>
              </Select>

            </Form.Item>

            <Form.Item label="League" name="league_id" rules={[{ required: true, message: "Must select a league." }]}>
              <Select labelInValue={true} placeholder="Please choose a league" options={leagueNames}>
              </Select>

            </Form.Item>


            <Form.Item>
              <Button type="primary" htmlType="submit" >Add Portfolio To League</Button>
            </Form.Item>
          </Form>
          <Form>
            <Form.Item>
              <Button  onClick={createPortfolio} >Or Create New Portfolio</Button>
            </Form.Item>
          </Form>
        </span>}
        {(portNames.length===0) &&
          <span>
            <div>No portfolios. Please create one.</div>
            <Button  onClick={createPortfolio} >Create Portfolio</Button>
          </span>
        }
      </span>
    }
    {((isCompleted) && 
    <div><p>Portfolio Successfully Created</p>
      <Form>
        <Form.Item>
          <Button  onClick={(e) => {setIsCompleted(false)}} >Add Portfolio to Another League</Button>
        </Form.Item>
      </Form>
      <Form>
        <Form.Item>
          <Button  onClick={goToThisPort} >Go To This Portfolio</Button>
        </Form.Item>
      </Form>
      <Form>
        <Form.Item>
          <Button  onClick={createPortfolio} >Create Another Portfolio</Button>
        </Form.Item>
      </Form>
      </div>
    )}
      {isErr && <div>{errMsg}<p>Failed to add Portfolio to League.</p>
      <Form>
        <Form.Item>
          <Button  onClick={tryAgain} >Try Again?</Button>
        </Form.Item>
      </Form></div>}
    </div>
  )
}

export default AddPortToLeague;