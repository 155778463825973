import React from "react";

import LeagueHome from "./LeagueHome";
import MyLeaguePorts from "./MyLeaguePorts";
import LeagueSettings from "./LeagueSettings";
import FrameContext from "./contexts/FrameContext.js";
import {Button} from "antd";
import { useContext} from "react";
import LeaguePerformance from "./LeaguePerformance";

const LeagueBody = (props) => {

  const componentMap = {
    "": null,
    Home: <LeagueHome leagueInfo={props.leagueInfo}/>,
    Portfolio: <MyLeaguePorts league_id={props.leagueInfo.league_id} portCallback={props.portCallback}/>,
    Settings: <LeagueSettings league_id={props.leagueInfo.league_id}/>,
    LeagueGraph: <LeaguePerformance leagueInfo={props.leagueInfo} />
  };

  const {setFrame} = useContext(FrameContext);

  const backToMyLeagues = () => {
    setFrame("MyLeagues");
  }

  return (
    <div>
      {componentMap[props.table]}
      <Button onClick={backToMyLeagues}>Back to My Leagues</Button>
    </div>
  )
}



export default LeagueBody;
