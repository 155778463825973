import React, { useState } from "react";
import ReactEcharts from "echarts-for-react";
import { useCallback, useEffect } from "react";
import useAxiosPrivate from "./hooks/useAxiosPrivate.js";

import { useMediaQuery } from 'react-responsive';


const PortfolioPerformance = (props) => {

  const axiosPrivate = useAxiosPrivate();
  const isXSmall = useMediaQuery({maxWidth:575});

  const [chartOptions,setChartOptions] = useState({})

  const getValues = useCallback(async () => {

    const dataSend = {port_id:props.port_id};
    console.log(dataSend);
    const response = await axiosPrivate.post('/portfolio/getValueHistory',dataSend);
    var yaxisData  = [];
    var xaxisData = [];
    console.log(response);
    response.data.forEach( (record) => {
      xaxisData.push("Week " + record.subtimeframe);
      yaxisData.push(Number(record.worth).toFixed(2));
    })

    const currWeek = xaxisData.length;
    xaxisData.push("Week " + currWeek)
    yaxisData.push(props.worth)
    console.log(yaxisData);
    console.log(xaxisData);

    var isPortGreen = true;
    if (response.data.length>0){
      isPortGreen = props.worth >= response.data[0].worth;
    }

    
    const options = {
      animationDuration: 2000,
      title: {
        text: 'Season Performance'
      },
      tooltip: {
        trigger: 'axis'
      },
      grid : {
        containLabel: true
      },
      xAxis: {
        type: 'category', //try value if this isnt good
        data: xaxisData,
        name: isXSmall ? "" : "Week",
      },
      yAxis: {
        type: 'value',
        /*min: function (value) {
          return value.min*0.95;
        },
        max: function (value) {
          return (value.max*1.05).toFixed(0);
        } */
        scale: true

      },
      series: [
        {
          data: yaxisData,
          type: 'line',
          label: {
            show: !isXSmall,
            formatter: '$ {c}'
          },
          color: isPortGreen ? 'green' : 'red'
        }
      ]
    };

    setChartOptions(options);

  },[axiosPrivate,props.port_id,props.worth,isXSmall])

  useEffect( () => {
    console.log("loading portfolio performance page")
    //console.log(props.worth);
    //console.log(props.portInfo.port_id)
      getValues();
    }, [getValues]); //empty dependency array means function only called once on mount







  return (
    <div >
        <ReactEcharts option={chartOptions}  />
    </div>
  )
}



export default PortfolioPerformance;
