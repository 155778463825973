import React from "react";

import PortfolioTrade from "./PortfolioTrade";
import PortfolioHome from "./PortfolioHome";
import PortfolioSettings from "./PortfolioSettings";
import FrameContext from "./contexts/FrameContext.js";
import {Button} from "antd";
import { useContext} from "react";
import PortfolioPerformance from "./PortfolioPerformance";
import TransactionHistory from "./TransactionHistory";

const PortBody = (props) => {

  const componentMap = {
    "": null,
    Home: <PortfolioHome portInfo={props.portInfo} leagueCallback={props.leagueCallback}/>,
    Trade: <PortfolioTrade portInfo={props.portInfo} tradeCallback={props.tradeCallback}/>,
    Performance: <PortfolioPerformance port_id={props.portInfo.port_id} worth={props.worth}></PortfolioPerformance>,
    Transactions: <TransactionHistory port_id={props.portInfo.port_id} />,
    Settings: <PortfolioSettings port_id={props.portInfo.port_id}/>
  };

  const {setFrame} = useContext(FrameContext);

  const backToMyPorts = () => {
    setFrame("MyPortfolios");
  }


  return (
    <div>
      {componentMap[props.table]}
      <Button onClick={backToMyPorts}>Back to My Portfolios</Button>
    </div>
  )
}



export default PortBody;
