

import React, { useState, } from "react";

import axios from './axios.js';

import useAxiosPrivate from "./hooks/useAxiosPrivate.js";

import { Button, Menu, Input,Form, Select } from "antd";




const  AdminPost = (props) => {



  const [view,setView] = useState("All");
  const [prevView,setPrevView] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const [isSuccess,setIsSuccess] = useState(false);
  const [postOptions,setPostOptions] = useState([]);
  //const [post,setPost] = useState(0);


  const successfulUpdate = () => {
    setIsSuccess(true);
    setPrevView(view);
    setView("All");
  }

  const repeatAction = () => {
    setView(prevView);
    setIsSuccess(false);
  }


  const createPost = async (e) => {
    console.log(e);
    const data = {
      title: e.title,
      msg: e.msg
    }
    console.log(data)
    await axiosPrivate.put('/post/create',data);
    successfulUpdate();
  }


  const handleClickAdmin = (e) => {
    //console.log("click ", e);
    console.log("handle click Admin");
    console.log(e);
    console.log(e.key);
    if (e.key==="inactivatePost"){
        getPosts();
    }
    else if (e.key==="activatePost"){
        getInactivePosts();
    }
    setIsSuccess(false);
    setView(e.key);

  };

  const getPosts = async () => {

    const response = await axios.get('/getpost/all');
    console.log(response);

    var posts = [];

    for (let i=0; i<response.data.length;++i){
        const record = response.data[i];
        const msg = record.msg;
        const post_id = record.post_id;
        //const title = record.title;
        posts.push({value:post_id,label:msg});

    }
    console.log(posts);
    setPostOptions(posts)

  }

  const getInactivePosts = async () => {

    const response = await axiosPrivate.get('/post/getInactive');
    console.log(response);

    var posts = [];

    for (let i=0; i<response.data.length;++i){
        const record = response.data[i];
        const msg = record.msg;
        const post_id = record.post_id;
        //const title = record.title;
        posts.push({value:post_id,label:msg});

    }
    console.log(posts);
    setPostOptions(posts)

  }


  const activatePost = (e, activation) => {
    //console.log("click ", e);
    console.log("Handle Post Click");
    console.log(e);
    console.log("post: " + e.post);
    console.log("activation: " + activation);
    //setPost(e.post);

    const data = {
        post_id: e.post,
        activate: activation
    }

    const response = axiosPrivate.put('/post/activate',data);
    console.log ("response")
    console.log(response);
    successfulUpdate();

  };

  const ChoosePost = (props) => (
    <Form onFinish={e=>activatePost(e,props.activate)}>
      <Form.Item label="Post" name="post" rules={[{ required: true, message: "Choose a Post." }]}>
        <Select  placeholder="Select a Post" options={postOptions}></Select>
      </Form.Item>
      <Form.Item>
          <Button type='primary' htmlType="submit">Inactivate Post</Button>
      </Form.Item>
    </Form>


  );

  const componentMap = {
    "": null,
    createPost: (
      <div>

        <div>
            <Form onFinish={e=>createPost(e)}>
                <Form.Item label="Title" name="title" rules={[{ required: true, message: "Provide a Title." }]}>
                <Input />
                </Form.Item>
                <Form.Item label="Message" name="msg" rules={[{ required: true, message: "Provide a message." }]}>
                <Input />
                </Form.Item>
                <Form.Item>
                <Button type='primary' htmlType="submit">Create Post</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
    ),
    activatePost: (
        <div>
            {<ChoosePost activate={1}></ChoosePost>}
        </div>

    ),
    inactivatePost: (
        <div>
            {<ChoosePost activate={0}></ChoosePost>}
        </div>

    )
  };

  return (
      <div>
        {isSuccess && <div>{prevView} Update Successful</div>}
        <Menu onClick={handleClickAdmin} mode='horizontal'>

            <Menu.Item key="createPost"><Button>Create Post</Button></Menu.Item>
            <Menu.Item key="activatePost"><Button>Activate Post</Button></Menu.Item>
            <Menu.Item key="inactivatePost"><Button>Inactivate Post</Button></Menu.Item>
        </Menu>



        {isSuccess && <Button onClick={repeatAction}>Repeat Action</Button>}
        {view!=="All" && <div>{view}</div>}
        {componentMap[view]}
      </div>
  );
  
}

export default AdminPost;
