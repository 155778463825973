import React, { useState, useEffect, useCallback } from "react";
import NFLTable from "./NFLTable";
import StockPerformance from "./StockPerformance";
import NCAABBtable from "./NCAABBtable";


const Market = (props) => {

  const [chartView, setChartView] = useState(false);
  const [eqID,setEqID] = useState(-1);
  const [name,setName] = useState("");


  const updateChartView = useCallback( (value) => {
    setChartView(value);
    if (props.setStockView) {
      props.setStockView(value);
    }
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect( () => {
    //console.log("here in market");
    updateChartView(false);
  }, [props.type, updateChartView]); 

  const backFunction = () => {
    updateChartView(false);
  }

  const showStockPerformance = (equity_id, name) => {
    setEqID(equity_id);
    setName(name);
    updateChartView(true);
  }



  const componentMap = {
    "": <NFLTable  type="Reg" showStockPerformance={showStockPerformance} transact={props.transact} purchaseCallback={props.purchaseCallback}/>,
    NFL: <NFLTable type={props.type} year={props.year} hist={props.hist} showStockPerformance={showStockPerformance}  transact={props.transact} purchaseCallback={props.purchaseCallback}/>,
    NCAAM: <NCAABBtable type={props.type} year={props.year} hist={props.hist} showStockPerformance={showStockPerformance}  transact={props.transact} purchaseCallback={props.purchaseCallback}/>,
  };






  return (
    <div>
      {!chartView && componentMap[props.sport]}
      {chartView && <StockPerformance backFunction={backFunction} eqID={eqID} name={name} hist={props.hist}/>}
    </div>
  )
}

export default Market;
