import { sportLeagueMap } from "./CONSTANTS.js";
import useAxiosPrivate from "./hooks/useAxiosPrivate.js";
import React, { useState, useEffect, useCallback } from "react";

const LeagueSettings = (props) => {

    const axiosPrivate = useAxiosPrivate();

    //const [isLoading, setIsLoading] = useState(false);
    const [leagueName,setLeagueName] = useState("");
    const [leagueFormat,setLeagueFormat] = useState("");
    const [leagueCreator,setLeagueCreator] = useState("");
    const [leagueTimeframe, setLeagueTimeframe] = useState("2023");
    const [leaguePrivate,setLeaguePrivate] = useState("No")
    const [leagueBudget,setLeagueBudget] = useState(0);
    const [leagueSport,setLeagueSport] = useState("");
    const [leaguePass,setLeaguePass] = useState("");
    const [leagueShorting,setLeagueShorting] = useState("");
    const [leagueTransType,setLeagueTransType] = useState(0);
    const [leagueTransTypeString,setLeagueTransTypeString] = useState("");
    const [leagueTransAmnt,setLeagueTransAmnt] = useState(0.0);
    const [leagueInseason,setLeagueInseason] = useState("Yes");

    const fetchData = useCallback( async() => {
        //setIsLoading(true);
        const dataSend = {league_id:props.league_id};
        console.log(dataSend);
        try {
            const response = await axiosPrivate.post('/league/getSettings',dataSend)

            const settings = response.data[0];

            console.log("get league settings data response");
            console.log(response.data); //debugger
            if (response?.data?.length>1){
                //got two sets of results for one league settings request, this shouldnt happen!
                console.log("Error in gettting league settings");
            }
            //setIsLoading(false);
            setLeagueName(settings.league_name);
            if (settings.lg_format===0){
                setLeagueFormat("Portfolio");
                setLeagueBudget(settings.budget)
                setLeagueTransType(settings.trans_cost_type);
                if (settings.trans_cost_type===1){
                    setLeagueTransTypeString("Global: $10 + 1%")
                }
                else{
                    setLeagueTransAmnt(settings.transaction_cost);
                    if (settings.trans_cost_type===0){
                        setLeagueTransTypeString("Free");
                    }
                    else if (settings.trans_cost_type===2){
                        setLeagueTransTypeString("Trade %");
                    }
                    else if (settings.trans_cost_type===3){
                        setLeagueTransTypeString("Flat Fee Per Trade");
                    }
                    else if (settings.trans_cost_type===4){
                        setLeagueTransTypeString("Fee Per Share");
                    }
                    
                }
            }
            setLeagueCreator(settings.username);
            if (settings.timeframe<0){
                setLeagueTimeframe(String(settings.timeframe*-1));
            }
            else {
                setLeagueTimeframe(settings.timeframe_name);
            }
            if (settings.private){
                setLeaguePrivate("Private");
                setLeaguePass(settings.lg_pass);
            }
            else {
                setLeaguePrivate("Public")
            }
            setLeagueSport(sportLeagueMap[settings.sport_league_id]);
            if (settings.shorting){
                setLeagueShorting("Allowed");
            }
            else {
                setLeagueShorting("Blocked")
            }
            if(settings.inseason){
                setLeagueInseason("Yes");
            }
            else {
                setLeagueInseason("No");
            }


        }
        catch (err){

        }
      }, [axiosPrivate,props.league_id])
    
      useEffect( () => {
        fetchData();
      }, [fetchData]);

      return (
        <div>
            <br></br>League Name: {leagueName}
            <br></br> League Creator: {leagueCreator}
            <br></br> Privacy: {leaguePrivate}
            {leaguePrivate==="Private" && <span>
            <br></br> Password: {leaguePass}</span>}
            <br></br> Sport: {leagueSport}
            <br></br> Timeframe: {leagueTimeframe}
            <br></br> League Format: {leagueFormat}
            <br></br><br></br>

            {leagueFormat==="Portfolio" &&
                <div> <b>Portfolio Format Settings:</b>
                    <br></br>Starting Budget: ${leagueBudget}
                    <br></br>Transaction Cost Type: {leagueTransTypeString}
                    {leagueTransType>1 && 
                    <span><br></br>Transaction Cost: {leagueTransAmnt}</span>}
                    <br></br>Shorting: {leagueShorting}
                    <br></br> In-Season Trading: {leagueInseason}
                </div>


            }


        </div>
      )

}


export default LeagueSettings;