import React, { Component } from "react";


class NewsBody extends Component {
  constructor() {
    super();

    this.componentMap = {
      "": null,
      All: <div>Breaking News</div>,
      NFL: (
        <div label="NFL">
          <div>Cook and Elliot both sign in AFC East. </div>
          <div>Barkley signs $11M deal </div>
          <div>Joe Burrow hurts calf; out several weeks</div>
        </div>
      ),
      NBA: (
        <div label="NBA">
          <div>Covid cancels games for 1st time this season</div>
          <div>First female Coach in NBA</div>
          <div>Bucks set NBA record with 29 3's </div>
          <div>Mavericks beat Clippers by 51!!!</div>
        </div>
      )
    };
  }

  render() {
    return (
      <div>
        {/* {console.log(this.props.table)} */}
        {this.componentMap[this.props.table]}
      </div>
    );
  }
}

export default NewsBody;
