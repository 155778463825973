import React from "react";
import "antd/dist/antd.min.css";
import axios from './axios';
import {Table} from "antd";

import { useCallback, useEffect, useState} from "react";

import {NFLteamFilters, NFLposFilters, positionMap} from './CONSTANTS';


const RestrictedTable = (props) => {
    //console.log("Constructor of restricted NFL table");

  const [isErr,setIsErr] = useState(false);
  const [isLoading,setIsLoading] = useState(true);
  const [tableData,setTableData] = useState([]);


  const playerColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter:  (a, b) => {
        if (a.name>b.name){
          return 1;
        }
        else {
          return -1;
        }
      }
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      sorter:  (a, b) => {
        if (a.position>b.position){
          return 1;
        }
        else {
          return -1;
        }
      },
      filters: NFLposFilters,
      onFilter: (value, record) => record.position===value
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
      sorter:  (a, b) => {
        if (a.team>b.team){
          return 1;
        }
        else {
          return -1;
        }
      },
      filters: NFLteamFilters,
      onFilter: (value, record) => record.team===value
    },
    {
      title: "Previous Price",
      dataIndex: "price",
      key: "price",
      sorter:  (a, b) => a.price - b.price
    },
    {
      title: "Restriction",
      dataIndex: "haltText"
    }
    /*{
      title: "Yards",
      dataIndex: "yards",
      key: "yards",
      sorter: {
        compare: (a, b) => a.yards - b.yards
      }
    } */
  ];


  const teamOnlycolumns = [
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
      sorter:  (a, b) => {
        if (a.team>b.team){
          return 1;
        }
        else {
          return -1;
        }
      }
    },
    {
      title: "Previous Price",
      dataIndex: "price",
      key: "price",
      sorter:  (a, b) => a.price - b.price
    }
  ];


    const fetchData =  useCallback(async()  => {

    var data = [];
    try {
      //console.log("get restricted nfl market response");
      var url = "restrictedNCAAMmarket";
      if (props.sport==="NFL"){
        url = "restrictedNFLmarket";
      }
      const res = await axios.get('/getmarket/'+url);

      //console.log(res.data); //debugger
      for (let i=0; i<res.data.length; ++i){
        var obj;
        var record = res.data[i];
        var haltedText = "";
        //console.log(record);
        if (record?.player_id){
          if(record?.active){
            const halt = record.halted;
            if (halt > 0){
              if (halt & 1){
                haltedText = "All trading halted.";
              }
              else {
                var started = false;
                if (halt & 2){
                  haltedText = "No Buying";
                  started = true;
                }
                else if (halt & 4){
                  haltedText = "No new purchases";
                  started = true;
                }
                if (halt & 8){
                  if (started){
                    haltedText = haltedText.concat(", No Selling");
                  }
                  else {
                    haltedText = "No Selling";
                  }
                }
                else if (halt & 16){
                  if (started){
                    haltedText = haltedText.concat(", No new shorting");
                  }
                  else {
                    haltedText = "No new shorting";
                  }
                }
              }
            }
            obj = {
              name: record.fname + " " + record.lname,
              position: positionMap[record.position_id],
              team: record.city + " " + record.nickname,
              price: record.price,
              ent_id: record.player_id,
              key: record.equity_id,
              restricted: record.restricted,
              halted: halt,
              haltText: haltedText
    
            }
          }
          else {
            continue;
          }

        }
        else {
          //console.log(record);
          if (record.restricted){
            haltedText = "Entire Team Locked"
          }
          obj = {
            name: record.city + " " + record.nickname,
            position: "TEAM",
            team: record.city + " " + record.nickname,
            price: record.price,
            ent_id: record.team_id,
            key: record.equity_id,
            restricted: record.restricted,
            halted: 0,
            haltText: haltedText
          }

        }

        if ((obj.restricted && obj.position==="TEAM") || obj.halted>0) data.push(obj)
      }
      //data.sort()
      setTableData(data);
      setIsLoading(false);
    
    }
    catch (err) {
      console.log(err);
      setIsLoading(false);
      setIsErr(true);
    }
  },[props.sport]);

  useEffect( () => {
    //console.log("here in restricted nfl table use effect");

    fetchData();
  }, [fetchData]); 

    return (
      <div>
        {!isErr &&
        <span>
        <p>List of Stocks that currently have trading halted:</p>
        {tableData.length>0 &&
        <Table
          dataSource={tableData}
          columns={props.sport==="NCAAM" ? teamOnlycolumns : playerColumns}
          pagination={false}
          loading={isLoading}
        />}
        {tableData.length===0 &&
        <div>No stocks currently halted.</div>}
        </span>}
        {isErr &&
        <p>Server Error. Failed to get market data.</p>}
      </div>
    );
}


export default RestrictedTable
