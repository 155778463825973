import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Tag } from "antd";
import "antd/dist/antd.min.css";
import useAxiosPrivate from "./hooks/useAxiosPrivate.js";
import StockPerformance from "./StockPerformance.js";
import { positionMap } from "./CONSTANTS.js";

import { useMediaQuery } from 'react-responsive';

const PortSellTable = (props) => {

  const isXSmall = useMediaQuery({maxWidth:575});

  const [isLoading, setIsLoading] = useState(false);
  const [tableData,setTableData] = useState([{}]);
  const [isChartView,setIsChartView] = useState(false);
  const [eqID,setEqID] = useState(-1);
  const [stockName,setStockName] = useState("");
  
  const axiosPrivate = useAxiosPrivate();


  const updateChartView = (value) => {
    setIsChartView(value);
    if (props.setChartView){
      props.setChartView(value);
    }
  }


  const fetchData = useCallback(async() => {

    setIsLoading(true);
    var data = [];
    const dataSend = {
      port_id: props.portInfo.port_id,
      timeframe: props.portInfo.port_timeframe
    };
    console.log(dataSend);
    console.log(props.portInfo);
    var obj;
    var record;
    if (props.portInfo.port_format===0){
      try {
        console.log("getting port equities for sell")
        const res = await axiosPrivate.post('/portfolio/getDataWithHist',dataSend)
        console.log("getportfolio data response");
        console.log(res.data); //debugger
        for (let i=0; i<res.data.length; ++i){
          record = res.data[i];
          console.log(record);
          var YTDchange;
          var change;
          var myChange;
          //console.log(record);
          if (record?.prevprice){
            //console.log(record.prevprice)
            change = (100*(Number(record.price)-Number(record.prevprice))/Number(record.prevprice)).toFixed(2);
          }
          else {
            change = null;
          }
          if (record?.origprice){
            YTDchange = (100*(record.price-record.origprice)/Number(record.origprice)).toFixed(2);
          }
          else {
            YTDchange = null;
          }
          if (record?.purchase_price){
            myChange = (100*(record.price-record.origprice)/record.origprice).toFixed(2);
            if (record.quantity<0){
              myChange = myChange*-1;
            }
          }
          else {
            myChange = null;
          }
          if (record?.player_id){
            obj = {
              name: record.fname + " " + record.lname,
              position: positionMap[record.position_id],
              team: record.city + " " + record.nickname,
              price: record.price,
              ent_id: record.player_id,
              key: record.equity_id,
              equity_id: record.equity_id,
              numshares: record.quantity,
              worth: (record.price*record.quantity).toFixed(2),
              restricted: record.restricted,
              change: change,
              YTDchange: YTDchange,
              myChange: myChange,
              myprice: record.purchase_price
            };
          }
          else {
            obj = {
              name: record.city + " " + record.nickname,
              position: "TEAM",
              team: record.city + " " + record.nickname,
              price: record.price,
              ent_id: record.team_id,
              key: record.equity_id,
              numshares: record.quantity,
              worth: (record.price*record.quantity).toFixed(2),
              restricted: record.restricted,
              equity_id: record.equity_id,
              change: change,
              YTDchange: YTDchange,
              myChange: myChange,
              myprice: record.purchase_price,
            }
          }
          if (obj.restricted){
            obj.name = obj.name + "*";
          }
          data.push(obj)
        }
        setTableData(data);
        setIsLoading(false);
      }
      catch (err){

      }
    }
    else if (props.portInfo.port_format===1){
      try {
        //console.log("getting port roster for sell")
        const res = await axiosPrivate.post('/portfolio/getRoster',dataSend)

        //console.log("getportfolio data response");
        //console.log(res.data); //debugger
        for (let i=0; i<res.data.length; ++i){
          record = res.data[i];
          //console.log(record);
          if (record?.player_id){
            obj = {
              name: record.fname + " " + record.lname,
              position: positionMap[record.position_id],
              team: record.city + " " + record.nickname,
              ent_id: record.player_id,
              restricted: record.locked,
              key: record.equity_id,
              equity_id: record.equity_id,
              multiplier: record.multiplier,
              numshares: 1
            };
          }
          else {
            obj = {
              name: record.city + " " + record.nickname,
              position: "TEAM",
              team: record.city + " " + record.nickname,
              ent_id: record.team_id,
              key: record.equity_id,
              equity_id: record.equity_id,
              restricted: record.locked,
              multiplier: record.multiplier,
              numshares: 1
            }
          }
          if (obj.restricted){
            obj.name = obj.name + "*";
          }
          data.push(obj)
        }
        setTableData(data);
        setIsLoading(false);
      }
      catch (err) {

      }
    }

  }, [axiosPrivate,props.portInfo])

  useEffect( () => {
    fetchData();
  }, [fetchData]); //empty dependency array means function only called once on mount

  const backFunction = () => {
    updateChartView(false);
  }

  const goToStockChart = (e,record) => {
    console.log(record.ent_id);
    setStockName(record.name);
    setEqID(record.equity_id);
    updateChartView(true);
  }

  var columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: {
        compare: (a, b) => a.price - b.price
      },
      hidden: !(props.portInfo.port_format===0)
    },
    {
      title: isXSmall ? "#" : "Number of Shares",
      dataIndex: "numshares",
      key: "numshares",
      sorter: {
        compare: (a, b) => a.numshares - b.numshares
      }
    },
    {
      title: isXSmall ? "Mult" : "Multiplier",
      dataIndex: "multiplier",
      key: "multiplier",
      sorter: {
        compare: (a, b) => a.multiplier - b.multiplier
      },
      hidden: (props.portInfo.port_format===0)
    },
    {
      title: "Total Worth",
      dataIndex: "worth",
      key: "worth",
      sorter: {
        compare: (a, b) => a.worth - b.worth
      },
      hidden: !(props.portInfo.port_format===0)
    },
    {
      title: "Weekly Change",
      dataIndex: "change",
      key: "change",
      responsive: ['sm'],
      sortDirections: ['descend','ascend'],
      sorter:  (a, b) => a.change - b.change,
      render : (text,record) => (
        record.change ? (record.change>0 ? <Tag color='green'>{text}%</Tag> : (record.change<0 ? <Tag color='red'>{text}%</Tag> : <Tag>{text}%</Tag>)) : <span>-</span>

      ),
      hidden: !(props.portInfo.port_format===0)
    },
    {
      title: isXSmall ? "Purchase Price" : "Avg Purchase Price",
      dataIndex: "myprice",
      key: "myprice",
      responsive: ['sm'],
      sorter: {
        compare: (a, b) => a.myprice - b.myprice
      },
      hidden: !(props.portInfo.port_format===0)
    },
    {
      title: "Gain",
      dataIndex: "myChange",
      key: "myChange",
      responsive: ['sm'],
      sortDirections: ['descend','ascend'],
      sorter:  (a, b) => a.myChange - b.myChange,
      render : (text,record) => (
        record.myChange ? (record.myChange>0 ? <Tag color='green'>{text}%</Tag> : (record.myChange<0 ? <Tag color='red'>{text}%</Tag> : <Tag>{text}%</Tag>)) : <span>-</span>

      ),
      hidden: !(props.portInfo.port_format===0)
    },
    {
      title: "",
      responsive: ['md'],
      render : (record) => (
        <Button onClick={(e) => {goToStockChart(e,record)}}>See Chart</Button>
      )
    },
    {
      title: "YTD Change",
      dataIndex: "YTDchange",
      key: "YTDchange",
      responsive: ['sm'],
      sortDirections: ['descend','ascend'],
      sorter:  (a, b) => a.YTDchange - b.YTDchange,
      render : (text,record) => (
        record.YTDchange ? (record.YTDchange>0 ? <Tag color='green'>{text}%</Tag> : (record.YTDchange<0 ? <Tag color='red'>{text}%</Tag> : <Tag>{text}%</Tag>)) : <span>-</span>

      ),
      hidden: !(props.portInfo.port_format===0)
    },
    {
      title: "Sell Shares",
      dataIndex: "key",
      key: "key",
      responsive: ['sm'],
      render : (text,record) => (
        !record.restricted ? <Button onClick={(e) => {props.sellCallback(e,record)}} >Sell Shares</Button> : <span></span>
      )
    },
    {
      title: "Sell",
      dataIndex: "key",
      key: "key",
      responsive: ['xs'],
      render : (text,record) => (
        !record.restricted ? <Button onClick={(e) => {props.sellCallback(e,record)}}  size='small'>Sell</Button> : <span></span>
      )
    }
  ].filter(item => !item.hidden);

  const expandableRows = {
    expandedRowRender: (record) => (
      <span>
        <div>Purchase Price: ${record.myprice} Gain: {record.myChange ? (record.myChange>0 ? <Tag color='green'>{record.myChange}%</Tag> : (record.myChange<0 ? <Tag color='red'>{record.myChange}%</Tag> : <Tag>{record.myChange}%</Tag>)) : <span>-</span>}</div>
        <div>
          Weekly Change: {record.change ? (record.change>0 ? <Tag color='green'>{record.change}%</Tag> : (record.change<0 ? <Tag color='red'>{record.change}%</Tag> : <Tag>{record.change}%</Tag>)) : <span>-</span>}
          YTD Change: {record.YTDchange ? (record.YTDchange>0 ? <Tag color='green'>{record.YTDchange}%</Tag> : (record.YTDchange<0 ? <Tag color='red'>{record.YTDchange}%</Tag> : <Tag>{record.YTDchange}%</Tag>)) : <span>-</span>}
        </div>
        <div>
          <StockPerformance eqID={record.equity_id} name={record.name}  mobile={true}/>
        </div>
      </span>
    ),
    rowExpandable: (record) => isXSmall
  }

    return (
      <span>
        {!isChartView && <div>
          <Table
            dataSource={tableData}
            columns={columns}
            pagination={false}
            loading={isLoading}
            size= {!isXSmall ? "large" : "small"}
            expandable={isXSmall ? expandableRows: null}
          />
        </div>}
        {isChartView && <StockPerformance backFunction={backFunction} eqID={eqID} name={stockName}/>}
      </span>
    );

  }

  export default PortSellTable