import {Checkbox, Form, Input, InputNumber, Radio, Button, Table} from "antd";
import React, { useState, useContext } from "react";
import useAxiosPrivate from "./hooks/useAxiosPrivate.js";
import FrameContext from "./contexts/FrameContext.js";

import { useMediaQuery } from 'react-responsive';

const CreateLeaguePage = (props) => {


  const axiosPrivate = useAxiosPrivate();

  const isXSmall = useMediaQuery({maxWidth:575});

  const [leaguePrivacy,setLeaguePrivacy] = useState(false);
  const [leagueFormat,setLeagueFormat] = useState("portfolio");
  const [leagueSport,setLeagueSport] = useState("NFL");
  const [numPlayersNeeded,setNumPlayersNeeded] = useState(false);
  const [isTransactionCost,setIsTransactionCost] = useState(true);
  const [seasonType,setSeasonType] = useState("Reg");
  const [leagueTransactionType,setLeagueTransactionType] = useState("global");
  const [delayCosts,setDelayCosts] = useState(true);
  const [lateJoin,setLateJoin] = useState(true);
  const [shorting,setShorting] = useState(true);
  const [inseasonTrading,setInseasonTrading] = useState(true);
  const [playoffMultipliers,setPlayoffMultipliers] = useState(false);
  const [numEntriesRestricted,setNumEntriesRestricted] = useState(false);
  const [totalEntriesRestricted,setTotalEntriesRestricted] = useState(false);
  const [teamPlayersRestricted,setTeamPlayersRestricted] = useState(false);
  const [isSubmitting,setIsSubmitting] = useState(false);
  const [isCompleted,setIsCompleted] = useState(false);
  const [isErr,setIsErr] = useState(false);
  const [errMsg,setErrMsg] = useState("");
  const [createdLgInfo,setCreatedLgInfo] = useState({});

  const {setFrame} = useContext(FrameContext);

  const createLeagueClick = async (e) => {
    console.log("here in create league submit");
    console.log(e);
    



    var data = e;
    data.private = leaguePrivacy;

    if (!e?.budget){
      data.budget = 0;
    }

    if (!e?.transcost){
      data.transcost = 0;
    }


    if (leagueSport==='NFL'){
      data.sport = 1;
      data.sport_league = 1;
    }
    else if (leagueSport==="NCAAM"){
      data.sport = 2;
      data.sport_league = 6;
    }
    else{
      data.sport = -1;
      data.sport_league = -1;
    }

    data.season_type = -1;
    if (seasonType==="Reg"){
      data.season_type = 2;
    }
    else if (seasonType==="Playoffs"){
      data.season_type = 3;
    }
    else if (seasonType==="Both"){
      data.season_type = -2;
    }


    if (!e?.numplayers){
      data.numplayers = -1;
    }

    if (!e?.maxentries){
      data.maxentries = -1;
    }

    if (!e?.maxtotentries){
      data.maxtotentries=-1;
    }

    if (!e?.maxteamplayers){
      data.maxteamplayers=-1;
    }

    data.playoffMultipliers = 0;
    data.delay = 0; //dont delay
    data.shorting = 0;
    data.options=0;
    data.transactionType = 0;

    data.format = -1;
    if (leagueFormat==='roster'){
      data.format = 1;
      data.cashless = true;
      data.dividendsonly = true;
      data.maxsharesperplayer = 1;
      data.scarcity = -1;
      data.roster = !teamPlayersRestricted ? PCrosterData: StackrosterData;
      if (seasonType==="Playoffs"){
        if (playoffMultipliers){
          data.playoffMultipliers = 1;
        }

      }
    }
    else if (leagueFormat==='portfolio'){
      data.format = 0;
      data.cashless = false;
      data.dividendsonly = false;
      data.maxsharesperplayer = -1;
      data.scarcity = -1;
      if (delayCosts){
        data.delay = 1; //delay
      }
      if (shorting){
        data.shorting = 1;
      }
      if (isTransactionCost){
        if (leagueTransactionType==='global'){
          data.transactionType = 1;
        }
        else if (leagueTransactionType==='percent'){
          data.transactionType = 2;
        }
        else if (leagueTransactionType==='pershare'){
          data.transactionType = 4;
        }
        else if (leagueTransactionType==='pertrans'){
          data.transactionType = 3;
        }
        
      }
    }

    data.late_join = 0; //dont allow late join
    if (lateJoin){
      data.late_join = 1; //allow late join
    }

    data.inseason = 0
    if (inseasonTrading){
      data.inseason = 1;
    }
  
    console.log(data);

    try {
      setIsErr(false);
      setIsSubmitting(true);
      const response = await axiosPrivate.post('/league/create',data);
      console.log(response);
      if (response?.data?.league_id){
        const lg_info = {
          league_id: response.data.league_id,
          league_name: e.league_name,
          lg_type: data.seasonType>0 ? Math.abs(data.seasonType)-1:3,
          week_start: data.seasonType===3 ? -1: 0,
          week_end: Math.abs(data.seasonType)===2 ? -2: -1,
          sport: e.sport
        };
        setCreatedLgInfo(lg_info);
      }

      setIsCompleted(true);
      setIsSubmitting(false);
      setIsTransactionCost(false);
      setNumEntriesRestricted(false);
      setNumPlayersNeeded(false);
      setTotalEntriesRestricted(false);
      setLeaguePrivacy(false);
      console.log("league create successful");

    }
    catch(err){
      console.log(err);
      console.log(err.response);
      if (err.response?.data?.message){
        console.log(err.response?.data?.message);
        setErrMsg("League Create Failed: " + err.response?.data?.message);
      }
      else {
        setErrMsg("League Create Failed: " + err.message);
      }
      setIsErr(true);
      setIsSubmitting(false);
    }
  }

  /*const privacyOnChage = (e) => {
    setLeaguePrivacy(e.target.value);
  } */

  const NFLrosterColumns = [
    {
      title: isXSmall ? "Pos" : "Position",
      dataIndex: "pos",
      key: "pos"
    },
    {
      title: isXSmall ? "#" : 'Quantity',
      dataIndex: 'quant',
      key: 'quant'
    }
  ];

  const PCrosterData = [
    {
      pos: 'QB',
      quant: 1
    },
    {
      pos: 'RB',
      quant: 2
    },
    {
      pos: 'WR',
      quant: 2
    },
    {
      pos: 'TE',
      quant: 1
    },
    {
      pos: 'K',
      quant: 1
    },
    {
      pos: 'TEAM',
      quant: 1
    },
    {
      pos: 'Bench',
      quant: 0
    },
    {
      pos: 'IR',
      quant: 0
    },

  ];

  const StackrosterData = [
    {
      pos: 'QB',
      quant: 2
    },
    {
      pos: 'RB',
      quant: 4
    },
    {
      pos: 'WR',
      quant: 4
    },
    {
      pos: 'TE',
      quant: 2
    },
    {
      pos: 'K',
      quant: 1
    },
    {
      pos: 'TEAM',
      quant: 1
    },
    {
      pos: 'Bench',
      quant: 0
    },
    {
      pos: 'IR',
      quant: 0
    },

  ];

  const formatOnChange = (e) => {

    if (seasonType==='Playoffs' && e.target.value==='roster'){
      setPlayoffMultipliers(true);
    }
    else {
      setPlayoffMultipliers(false);
    }

    if (e.target.value!=="portfolio"){
      setLeagueTransactionType("percent");
      setIsTransactionCost(false);
    }
    if (e.target.value==='portfoliio' || e.target.value==='roster'){
      setInseasonTrading(true);
    }

    if (e.target.value!=="portfolio" && e.target.value!=="bestball" && e.target.value!=='roster'){
      setNumPlayersNeeded(true);
    }
    else{
      setNumPlayersNeeded(false);
    }
    setLeagueFormat(e.target.value);
  }

  const numPlayersOnChange = (e) => {
    setNumPlayersNeeded(e.target.value);
  }

  const numMaxEntriesOnChange = (e) => {
    setNumEntriesRestricted(e.target.value);
  }

  const numTotEntriesOnChange = (e) => {
    setTotalEntriesRestricted(e.target.value);
  }

  const numTeamPlayersOnChange = (e) => {
    setTeamPlayersRestricted(e.target.value);
  }

  const seasonTypeOnChange = (e) => {
    setSeasonType(e.target.value);
    if (e.target.value==='Playoffs' && leagueFormat==='roster'){
      setPlayoffMultipliers(true);
    }
    else {
      setPlayoffMultipliers(false);
    }
  }

  const sportOnChange = (e) => {
    console.log(e.target.value);
    setLeagueSport(e.target.value)
  }

  const transactionTypeOnChange = (e) => {
    setLeagueTransactionType(e.target.value);
  }

  /*const setIsCompletedFalse = (e) => {
    setIsCompleted(false);
  }*/

  const createPortForLeague = (e) => {
    setFrame("CreatePortfolio");
  }

  const goToMyLeague = () => {
    props.leagueCallback(createdLgInfo);
    setFrame("Leagues");
  }

  const tryAgain = () => {
    setLeagueTransactionType("global");
    setNumEntriesRestricted(false);
    setNumPlayersNeeded(false);
    setTotalEntriesRestricted(false);
    setCreatedLgInfo({});
    setErrMsg("");
    setIsErr(false);
  }

  const layout = {
    labelCol: {span:6},
    wrapperCol: {span:14}
  }

  //console.log("creating league page");
  return (

    <div>
      {(isSubmitting) &&
      <div>Creating League...</div>
      }
      {(!isSubmitting &&  !isCompleted && !isErr) &&
    <Form {...layout}  onFinish={createLeagueClick}>
      <Form.Item label="League Name" name="leaguename" rules={[{ required: true, message: "League name required." }]}>
        <Input />
      </Form.Item>

      <Form.Item>
        <Checkbox onChange={(e) => setLeaguePrivacy(e.target.checked)}>Private</Checkbox>
      </Form.Item>
      {leaguePrivacy===true && <Form.Item label="League Password" name="leaguepass" rules={[{ required: true, message: "Provide a password or uncheck private league setting." }]}>
        <Input />
      </Form.Item>}
      <Form.Item label="Sport" >
        <Radio.Group value={leagueSport} onChange={(sportOnChange)} >
          {<Radio value="NFL">NFL</Radio>}
          {/*<Radio value="NCAAM">NCAAM</Radio>*/}
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Season Type"> 
            <Radio.Group value={seasonType} onChange={(seasonTypeOnChange)}>
              {leagueSport==="NFL" && <Radio value="Reg">Regular Season</Radio>}
              {/*<Radio value="Playoffs">Playoffs</Radio>*/}
            </Radio.Group>
          </Form.Item>
      {(seasonType==="Playoffs" && leagueSport==="NFL" && <Form.Item label="Format:">
        <Radio.Group value={leagueFormat} onChange={(formatOnChange)}>
          <Radio value="portfolio">Stock Based</Radio>
          <Radio value="roster">Roster Based</Radio>
          {/* <Radio value="draft">Draft Based</Radio>
          <Radio value="classic">Classic</Radio>
          <Radio value="auction">Auction</Radio>
          <Radio value="scarcity">Scarcity</Radio>
          <Radio value="bestball">Best Ball</Radio> */}
        </Radio.Group>
      </Form.Item>)}
      {(leagueFormat==="portfolio" || leagueFormat==="roster") &&
        <Form.Item label="Limit Number of People?"> 
          <Radio.Group onChange={numPlayersOnChange} value={numPlayersNeeded}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      }
      {(numPlayersNeeded &&
        <Form.Item label="Max Number of People" name="numplayers" rules={[{ required: true, message: "Enter a max number of players in league" }]}>
          <InputNumber min={1} precision={0} />
        </Form.Item>)
      }
      {(leagueFormat==="portfolio" || leagueFormat==="roster") &&
        <Form.Item label="Limit Number of Entries Per Person?"> 
          <Radio.Group onChange={numMaxEntriesOnChange}  value={numEntriesRestricted}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      }
      {(numEntriesRestricted &&
        <Form.Item label="Max Number of Entries" name="maxentries" rules={[{ required: true, message: "Enter a max number of entries per player." }]}>
          <InputNumber min={1} precision={0} />
        </Form.Item>)
      }
      {(leagueFormat==="portfolio" || leagueFormat==="roster") &&
        <Form.Item label="Limit Total Number of Entries in League?"> 
          <Radio.Group onChange={numTotEntriesOnChange}  value={totalEntriesRestricted}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      }
      {(totalEntriesRestricted &&
        <Form.Item label="Max Total Number of Entries" name="maxtotentries" rules={[{ required: true, message: "Enter a max number of entries for the league." }]}>
          <InputNumber min={1} precision={0} />
        </Form.Item>)
      }
      {(leagueFormat==="portfolio" &&
        <Form.Item initialValue={100000} label="Starting Budget" name="budget" rules={[{ required: true, message: "Budget required." }]}>
          <InputNumber  precision={0} step={1} style={{width:150}} defaultValue={100000} addonBefore="$" formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} min={1000} />
        </Form.Item>
      )}
      {(leagueFormat==="portfolio")  &&
          (<Form.Item>
            <Checkbox checked={isTransactionCost} onChange={(e) => setIsTransactionCost(e.target.checked)}>Include Transaction Cost</Checkbox>
          </Form.Item>)

        }

        {((isTransactionCost && leagueFormat==="portfolio") && 
          <Form.Item label="Transaction Type"> 
            <Radio.Group defaultValue="global" onChange={(transactionTypeOnChange)}>
              <Radio value="global">Global ($10 + 0.5%)</Radio>
              <Radio value="percent">Percentage of Transaction</Radio>
              <Radio value="pershare">Fixed $ Per Share</Radio>
              <Radio value="pertrans">Fixed $ Per Transaction</Radio>
            </Radio.Group>
          </Form.Item>

        )}
        {((isTransactionCost && leagueFormat==="portfolio" && leagueTransactionType==="percent") &&
            <Form.Item label="Transaction Cost" name="transcost" rules={[{ required: true, message: "Enter a cost or deselect the 'Include Transaction Cost' option." }]}>
              {/*<InputNumber formatter={(value) => `${value}%`} parser={(value) => value.replace('%', '')} min={0} max={100}/>*/}
              {<InputNumber addonAfter="%" precision={2} step={0.1} min={0} max={100}/>}
            </Form.Item>

        )}
        {((isTransactionCost && leagueFormat==="portfolio" && leagueTransactionType!=="percent" && leagueTransactionType!=="global") &&
            <Form.Item label="Transaction Cost" name="transcost" rules={[{ required: true, message: "Enter a cost or deselect the 'Include Transaction Cost' option." }]}>
              <InputNumber addonBefore="$" precision={2} formatter={value => `${value}`} parser={value => value.replace('$','')} min={0}/>
            </Form.Item>

        )}
        {((isTransactionCost && leagueFormat==="portfolio") && 
          <Form.Item>
            <Checkbox checked={delayCosts} onChange={(e) => setDelayCosts(e.target.checked)}>Delay Costs Until Season Starts</Checkbox>
          </Form.Item>


        )}
        {((leagueFormat==="portfolio" || leagueFormat==="roster") && 
          <span>
            <Form.Item>
              <Checkbox checked={inseasonTrading} onChange={(e) => setInseasonTrading(e.target.checked)}>Allow In-Season Trading</Checkbox>
            </Form.Item>
          </span>
        )}
        {((leagueFormat==="portfolio") && 
          <span>
            <Form.Item>
              <Checkbox checked={shorting} onChange={(e) => setShorting(e.target.checked)}>Allow Shorting (Negative Shares)</Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox checked={lateJoin} onChange={(e) => setLateJoin(e.target.checked)}>Allow Players to Join After Season Starts</Checkbox>
            </Form.Item>
          </span>
        )}

      {(leagueFormat==='roster' && 
        <span>
          {seasonType==='Playoffs' && <Form.Item>
              <Checkbox checked={playoffMultipliers} onChange={(e) => setPlayoffMultipliers(e.target.checked)}>Playoff Multipliers</Checkbox>
            </Form.Item>}
          <Form.Item label="Limit Number of Players from a Single Team?"> 
            <Radio.Group onChange={numTeamPlayersOnChange}  value={teamPlayersRestricted}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {teamPlayersRestricted &&
            <Form.Item label="Max Number of Players from Single Team" name="maxteamplayers" rules={[{ required: true, message: "Enter a max number of players from a single team." }]}>
              <InputNumber min={1} precision={0} />
            </Form.Item>
          }
          <div>
          <Table
          dataSource={!teamPlayersRestricted ? PCrosterData: StackrosterData}
          columns={NFLrosterColumns}
          pagination={false}
          size= {"small"} />
          </div>
        </span>
      )}


      <Form.Item>
        <Button type="primary" htmlType="submit" >Create League</Button>
      </Form.Item>
    </Form>}
    {((isCompleted) && 
    <div><p>League Successfully Created</p>
      <Form>
        <Form.Item>
          <Button  onClick={(e) => {setIsCompleted(false)}} >Create Another League</Button>
        </Form.Item>
      </Form>
      <Form>
        <Form.Item>
          <Button  onClick={goToMyLeague} >Go To My Created League</Button>
        </Form.Item>
      </Form>
      <Form>
        <Form.Item>
          <Button  onClick={createPortForLeague} >Create Portfolio for League</Button>
        </Form.Item>
      </Form>
      </div>
    )}
    {isErr && <div>{errMsg}
      <Form>
        <Form.Item>
          <Button  onClick={tryAgain} >Try Again?</Button>
        </Form.Item>
      </Form></div>}
    </div>
  )
}

export default CreateLeaguePage;